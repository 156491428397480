import { useEffect, useRef, useState } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

const checkUrl = "check.png"
const addPhotoUrl = "add-a-photo.png"

const defaultTheme = {
    palette: {
        primary: {
            main: 'red',
        },
        secondary: {
            main: 'blue',
        },
    },
};

const convertCoords = (position, distance) => {
    const phi = (90 - position.altitude) * (Math.PI / 180);
    const theta = (-position.azimuth) * (Math.PI / 180);
    return {
        x: -(distance * Math.sin(phi) * Math.cos(theta)),
        y: distance * Math.cos(phi),
        z: distance * Math.sin(phi) * Math.sin(theta)
    };
}



const lookAtTimeoutThreshold = 500;



export default function SnapshotPositionMarker({ position = { azimuth: 0, altitude: -89, taken: false }, distance = 6, scale = 3, theme = defaultTheme, verbose = false, takeSnapshot = null, setHintArrowDir = null }) {
    const { scene, camera } = useThree();
    const meshRef = useRef();
    const [checked, setChecked] = useState(position.taken)
    const photoUrl = checked ? checkUrl : addPhotoUrl
    const circleColor = checked ? theme.palette.secondary.main : theme.palette.primary.main
    const [size, setSize] = useState(scale)
    const [lookAtTimeout, setLookAtTimeout] = useState(null);

    verbose && console.log("SnapshopPositionMarker", position)

    function isCameraLookingAtMarker(camera, markerPosition, angleThreshold = 10) {

        const cameraForward = new THREE.Vector3(0, 0, -1).applyQuaternion(camera.quaternion);

        const toMarker = new THREE.Vector3().subVectors(markerPosition, camera.position);

        cameraForward.normalize();
        toMarker.normalize();

        const angle = cameraForward.angleTo(toMarker);

        return angle < (Math.PI / 180 * angleThreshold);
    }

    const removeMarker = () => {
        if (meshRef.current) {
            scene.remove(meshRef.current.imageMesh);
            scene.remove(meshRef.current.circleMesh);
        }
    }

    useFrame(() => {
        if (meshRef && meshRef.current && !checked) {
            const lookingAtMarker = isCameraLookingAtMarker(camera, meshRef.current.imageMesh.position)
            setSize(lookingAtMarker ? scale * 1.2 : scale);
            // meshRef.current.scale.lerp(new THREE.Vector3(size, size, size), 0.1);

            if (lookingAtMarker) {
                // If the camera is looking at the marker and there's no timeout set, set a timeout
                if (!lookAtTimeout) {
                    setLookAtTimeout(setTimeout(() => {
                        // The camera has been looking at the marker for 200ms
                        verbose && console.log(`Camera has been looking at the marker for ${lookAtTimeoutThreshold}ms`);
                        takeSnapshot && takeSnapshot();
                        setSize(scale);
                        setLookAtTimeout(null); // Clear the timeout
                        setChecked(true)
                        // removeMarker()
                    }, lookAtTimeoutThreshold));
                }
            } else {
                // If the camera is not looking at the marker and there's a timeout set, clear the timeout
                if (lookAtTimeout) {
                    clearTimeout(lookAtTimeout);
                    setLookAtTimeout(null);
                }


            }
        }
    });

    useEffect(() => {

        const loader = new THREE.TextureLoader();
        loader.load(photoUrl, texture => {

            const imageMaterial = new THREE.MeshBasicMaterial({
                map: texture,
                transparent: true,
                depthTest: true,
                depthWrite: true
            });

            const circleMaterial = new THREE.MeshBasicMaterial({
                color: circleColor,
                transparent: false,
                depthTest: true,
                depthWrite: true
            });

            const imageGeometry = new THREE.PlaneGeometry(size / 10, size / 10);
            const circleGeometry = new THREE.CircleGeometry(size / 9, 32); // make the circle slightly larger

            const imageMesh = new THREE.Mesh(imageGeometry, imageMaterial);
            const circleMesh = new THREE.Mesh(circleGeometry, circleMaterial);

            const imagePos = convertCoords(position, distance);
            imageMesh.position.set(imagePos.x, imagePos.y, imagePos.z);
            const circlePos = convertCoords(position, distance * 1.1);
            circleMesh.position.set(circlePos.x, circlePos.y, circlePos.z);

            // Orient the meshes towards the center of the sphere
            imageMesh.lookAt(new THREE.Vector3(0, 0, 0));
            circleMesh.lookAt(new THREE.Vector3(0, 0, 0));

            scene.add(imageMesh);
            scene.add(circleMesh);
            meshRef.current = { imageMesh, circleMesh };

        });

        return () => {
            removeMarker()
        };
    }, [position, distance, scale, checked, size]);

    return null;
}