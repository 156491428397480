import DragCanvas from './DragCanvas';
import HudCanvas from './HudCanvas';
import { DeviceOrientationControls, Sphere, PerspectiveCamera } from '@react-three/drei'
import { Scene, OrthographicCamera } from 'three';

import { useRef, useEffect, useState } from 'react';
import ARSphere from './ARSphere';
import ImagePlane from './ImagePlane';
import SunOverlay from './SunOverlay';
import SeekerOverlayThree from './SeekerOverlayThree';
import CoverScreen from './CoverScreen';

import { Button, Select, MenuItem, InputLabel, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { createPanorama } from '../CreatePanorama'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useTranslation } from 'react-i18next';
import { Euler, Quaternion } from 'three';
import { connect } from 'react-redux';
import { Typography } from '@mui/material'

import SnapshotPositionMarker from './SnapshotPositionMarker';
import { startCompassListener } from './compassReading'
import { TramRounded } from '@mui/icons-material';

import { toLimits, getViewDirection, radToDeg } from './utils';
import TextHintOverlay from './TextHintOverlay';


const requestedSnapshotAltitude = 20
let requestedSnapshotOrientations = [
    { "azimuth": -80, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false },
    { "azimuth": -60, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false },
    { "azimuth": -20, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false },
    { "azimuth": 0, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false },
    { "azimuth": 20, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false },
    { "azimuth": 60, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false },
    { "azimuth": 80, "altitude": requestedSnapshotAltitude, "roll": 0, "taken": false }
]
// requestedSnapshotOrientations = [
//     { "azimuth": 0, "altitude": 20, "roll": 0 }
// ]

const nextAppStatus = ["readyForProcessing", "creatingPanorama"][1]

const dev = false
const videoOn = true
const buttonsVisible = false
const textHintsVisible = true

export default function ARCanvas({
    children,
    panoramaImages,
    setPanoramaImages,
    setStitchedPanorama,
    worker,
    setAppStatus,
    scrollToRef,
    setBalconyAzimuth,
    cameraStream
}) {

    const { t } = useTranslation();
    const theme = useTheme();
    const [headingOffset, setHeadingOffset] = useState(0);
    const [controlsOffsetInitialized, setControlsOffsetInitialized] = useState(dev ? true : false);
    const [calibrationDone, setCalibrationDone] = useState(dev ? true : false);
    const [positionReady, setPositionReady] = useState(dev ? true : false);
    const cameraRef = useRef();
    const controlsRef = useRef();
    const videoRef = useRef();
    const snapshotCanvasRef = useRef();
    const [snapshotResolution, setSnapshotResolution] = useState(.5);
    const [deviceOrientationAccessGranted, setDeviceOrientationAccessGranted] = useState(false)

    // const [mainCamera, setMainCamera] = useState(null)

    const [hintArrowDir, setHintArrowDir] = useState([])

    const [requestedSnapshots, setRequestedSnapshots] = useState(requestedSnapshotOrientations)


    const [info, setInfo] = useState({});


    const appendInfo = (data) => { setInfo(prevInfo => ({ ...prevInfo, ...data })) }

    useEffect(() => {
        videoOn && startVideo()
    }, []);

    const startVideo = async () => {
        const video = videoRef.current;

        if (!cameraStream) {
            video.srcObject = await navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
        } else {
            video.srcObject = cameraStream;
        }

        video.muted = true;
        video.play().catch(error => {
            // Auto-play was prevented
            // Show a UI element to let the user manually start playback
            alert("iOS needs another confirmation for camera access. Please allow camera access here too.")
        });
        // navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } })
        //     .then(stream => {
        //         const video = videoRef.current;
        //         video.srcObject = stream;
        //         video.muted = true;
        //         video.play().catch(error => {
        //             // Auto-play was prevented
        //             // Show a UI element to let the user manually start playback
        //             alert("Please allow camera access")

        //         });
        //     })
        //     .catch(err => console.error(err));
    }




    
    
    // TODO move to separate file 
    const takeSnapshot = () => {
        if (!videoOn) return

        const video = videoRef.current;
        const canvas = snapshotCanvasRef.current;
        const context = canvas.getContext('2d');

        const height = Math.floor(video.videoHeight * snapshotResolution);
        const width = Math.floor(video.videoWidth * snapshotResolution)

        // Set the canvas height and width to match the video feed
        canvas.width = width;
        canvas.height = height;

        // Draw the video feed onto the canvas and get the image data
        context.drawImage(video, 0, 0, width, height);
        const imageData = context.getImageData(0, 0, width, height)
        console.log("image Data height and width", imageData.height, imageData.width)

        // Get a data URL representing the image
        const imageUrl = canvas.toDataURL('image/png');

        // Save the current view direction
        const viewDirection = getViewDirection(cameraRef);

        // Download the image
        if (false) {
            const imageLink = document.createElement('a');
            imageLink.href = imageUrl;
            imageLink.download = 'image.png';  // or any other filename
            imageLink.click();

            const viewDirectionJson = JSON.stringify(viewDirection);
            const viewDirectionBlob = new Blob([viewDirectionJson], { type: 'application/json' });
            const viewDirectionBlobUrl = URL.createObjectURL(viewDirectionBlob);

            const jsonLink = document.createElement('a');
            jsonLink.href = viewDirectionBlobUrl;
            jsonLink.download = 'viewDirection.json';  // or any other filename
            jsonLink.click();
        }

        // Add the image URL and view direction to the list of panorama images
        if (panoramaImages) {
            setPanoramaImages(images => [...images, { imageUrl, viewDirection, imageData }])
        } else {
            setPanoramaImages([{ imageUrl, viewDirection, imageData }])
        }

        // set the snapshot as taken, if orientation is close
        const tolerance = 10
        const updatedSnapshots = requestedSnapshots.map((snapshot) => {
            const azimuthDiff = Math.abs(snapshot.azimuth - viewDirection.azimuth)
            const altitudeDiff = Math.abs(snapshot.altitude - viewDirection.altitude)

            if (azimuthDiff < tolerance && altitudeDiff < tolerance) {
                snapshot.taken = true
                console.log("snapshot ok", snapshot.azimuth, viewDirection.azimuth)
            }
            return snapshot
        })

        // if (updatedSnapshots.every(snapshot => snapshot.taken)) {
        //     setAppStatus("readyForProcessing")
        // }

        setRequestedSnapshots(updatedSnapshots)

        console.log(panoramaImages)
    };


    const displayPanoramaImages = () => {
        return panoramaImages.map((image, index) => (
            <ImagePlane key={`image-${index}`} imageUrl={image.imageUrl} position={image.viewDirection} distance={6} scale={3} />
        ))
    }

    const displaySnapshotPositions = () => {
        return requestedSnapshots.map((position, index) => (
            <SnapshotPositionMarker key={`snapshot-${index}`} position={position} theme={theme} takeSnapshot={takeSnapshot} setHintArrowDir={setHintArrowDir} />
        ))
    }

    const buttonBoxSx = {
        width: "33%",
        display: "flex",
        flexGrow: 0,
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        },
    }

    const nextSwitchCameraButtons = () => {
        // if (panoramaImages.length < 2) {
        if (false) {
            return
            // <Button variant="contained" color="primary"
            //     onClick={() => { setAppStatus("readyForProcessing") }}>
            //     Switch Camera
            // </Button >

        } else {
            return <Button variant="contained" color="primary" disabled={(panoramaImages.length < 2)}
                onClick={() => { setAppStatus("readyForProcessing") }
                }>{t("Next")}</Button>

        }
    }

    useEffect(() => {
        const allTaken = requestedSnapshots.every(snapshot => snapshot.taken);
        if (allTaken) {
            setAppStatus(nextAppStatus);
        }
    }, [requestedSnapshots])


    useEffect(() => {
        startCompassListener(
            (compass) => {
                console.log("compass", compass)
                appendInfo({ compass: compass })
            },
            (offset) => {
                setHeadingOffset(offset)

                const controls = controlsRef.current;
                if (controls && controls.alphaOffset != undefined) {
                    const offsetInRadians = offset * (Math.PI / 180)
                    controls.alphaOffset = - offsetInRadians;
                }
        
            },
            (heading) => {
                appendInfo({ heading: heading })
            },
            controlsOffsetInitialized,
            setControlsOffsetInitialized
        )
    }, [])

    const showInfo = () => {
        return <>{Object.entries(info).map((data, index) => (
            <p key={index}>{data[0]}: {(typeof data[1] === 'number') ? data[1].toFixed(0) : data[1]}</p>
        ))}</>
    }

    
    const handlePositionReadyClicked = () => {
        const currentHeading = getViewDirection(cameraRef).azimuth
    
        setBalconyAzimuth(currentHeading)
        setRequestedSnapshots(requestedSnapshotOrientations.map((orientation) => {
            return { ...orientation, azimuth: toLimits(currentHeading + orientation.azimuth) }
        }))
        setPositionReady(true);
    }



    return (<>

        {!calibrationDone &&
            <CoverScreen zIndex={100}>
                <Typography>
                    {t("calibrate compass")}
                </Typography>
                <img style={{ width: "100%", maxWidth: "300px" }} src="calibrate.gif" alt="calibrate" />
                <Box sx={buttonBoxSx}>
                    <Button variant="contained" color="primary" disabled={!controlsOffsetInitialized}
                        onClick={() => {
                            setCalibrationDone(true);
                            // stopCompassListener()
                        }
                        }>{t("Next")}</Button>
                </Box>
                {/* {showInfo()} */}
            </CoverScreen>}

        {!positionReady &&
            <CoverScreen zIndex={99}>
                <Typography>
                    {t("Get ready 1")}
                </Typography>
                <img style={{ width: "100%", maxWidth: "300px", transform: "rotate(90deg)" }} src="lookfrombalcony.png" alt="Look off the balcony scheme" />
                <Typography>
                    {t("Get ready 2")}
                </Typography>
                <Box sx={buttonBoxSx}>
                    <Button variant="contained" color="primary" disabled={!controlsOffsetInitialized}
                        onClick={handlePositionReadyClicked}>{t("Next")}</Button>
                </Box>
                {/* {showInfo()} */}
            </CoverScreen>}

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "end", alignItems: "center", position: 'relative', width: '100%', height: '100vh' }}>


            {videoOn && <video id="video" playsInline muted ref={videoRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}></video>}

            <DragCanvas setHeadingOffset={setHeadingOffset} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
                <PerspectiveCamera makeDefault position={[0, 0, 0]} ref={cameraRef} />
                <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
                <ARSphere />

                {calibrationDone && displaySnapshotPositions()}

                <DeviceOrientationControls ref={controlsRef} />

                {/* {panoramaImages && (panoramaImages.length > 0) && displayPanoramaImages()} */}


                <SunOverlay />

            </DragCanvas>

            <HudCanvas style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 3 }} >
                <SeekerOverlayThree
                    // color={"black"}
                    color={theme.palette.primary.dark}
                    mainCameraRef={cameraRef}
                    requestedSnapshots={requestedSnapshots}
                />
            </HudCanvas>


            {(headingOffset != 0) && `  offset: ${headingOffset}°  `}

            {dev && false && <Box style={{ margin: 10, padding: 10, backgroundColor: "white", position: 'relative' }}>
                Info
                {showInfo()}
            </Box>}

            {textHintsVisible && <TextHintOverlay positionsPending={requestedSnapshots.filter(snapshot => !snapshot.taken).length} />}

            {buttonsVisible && <Box style={{ display: "flex", margin: 30, flexDirection: "row", justifyContent: "space-between", position: 'relative', width: '90%', zIndex: 10 }}>


                <Box sx={buttonBoxSx}>
                    {/* <InputLabel id="snapshot-resolution-label">Resolution</InputLabel>
                    <Select value={snapshotResolution} variant="filled" onChange={(e) => {

                        setSnapshotResolution(e.target.value)
                    }}>
                        <MenuItem value={1}>1x</MenuItem>
                        <MenuItem value={0.75}>0.75x</MenuItem>
                        <MenuItem value={0.5}>0.5x</MenuItem>
                        <MenuItem value={0.25}>0.25x</MenuItem>
                    </Select> */}

                    {/* <Button variant="contained" color="primary" disabled={(panoramaImages.length > 0)}
                        onClick={() => { setAppStatus("loadingTestData") }
                        }>{t("Use Test Data")}</Button> */}

                </Box>

                <Box sx={buttonBoxSx}>
                    <Button variant="contained" sx={{ padding: 9, borderRadius: "100px" }} color="primary" onClick={takeSnapshot} >
                        {/* Foto Aufnehmen */}
                        <AddAPhotoIcon />
                    </Button>
                </Box>

                <Box sx={buttonBoxSx}>
                    {nextSwitchCameraButtons()}
                </Box>

                <Box sx={buttonBoxSx}>

                    {/* <Button onClick={

                        () => {
                            const video = videoRef.current;
                            video.play().then(() => {
                                // setIsPlaying(true);
                            }).catch(error => {
                                alert("Playback failed");
                            });
                        }

                    }>
                        Play
                    </Button> */}

                </Box>


            </Box>}

        </div >



        <div >
            <canvas ref={snapshotCanvasRef} style={{ display: 'none' }} />



        </div>



    </>)
}