const systemsToCompare = [
    {
        id: 1,
        name: 'Sonnèlier 300',
        wattage: 300,
        price: 549,
        amortizationTime: 99,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "zweihorn ENERGY",
        brandLogo: "",
        imageSrc: "https://www.startnext.com/cdn-cgi/image/width=450,height=450,fit=cover,format=auto,quality=80/media/tyfo1352548-e1bbd7078752334a5b35742aad74ea27/240308_startnext_Dankeschoens-05.jpg",
        orderLink: "https://www.startnext.com/zweihorn-energy/ds/d/sn-technik/sn-gadgets/balkonkraftwerk-modell-quotsonnelier-300quot-in-schwarz-i419771.html",
        tiltRange: [60, 90]
    },
    {
        id: 2,
        name: 'Sonnèlier 600',
        wattage: 600,
        price: 1049,
        amortizationTime: 199,
        savingsCO2: 0,
        savingsEuro: 0,
        brand: "zweihorn ENERGY",
        brandLogo: "",
        imageSrc: "https://www.startnext.com/cdn-cgi/image/width=450,height=450,fit=cover,format=auto,quality=80/media/tyfo1353441-0f029339c4bd8f5e4b36484d92c0c423/240308_startnext_Dankeschoens_07_Sonnelier600schwarz-1.jpg", orderLink: "https://www.startnext.com/zweihorn-energy/ds/d/sn-technik/sn-gadgets/balkonkraftwerk-modell-quotsonnelier-600quot-in-schwarz-i419774.html",
        tiltRange: [60, 90]
    }
]

export default systemsToCompare