import SunCalc from 'suncalc'

export const getAverageSunAboveHorizonHoursForMonth = (month, lat, lng) => {

    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let sunAboveHorizonHoursMonth = 0;
    for (let i = 1; i <= daysInMonth[month - 1]; i++) {
        const date = new Date(2021, month, i);
        const sunTimes = SunCalc.getTimes(date, lat, lng);
        const sunAboveHorizonHoursForDay = (sunTimes.sunsetStart - sunTimes.sunrise) / 1000 / 60 / 60;
        sunAboveHorizonHoursMonth += sunAboveHorizonHoursForDay;
    }
    // console.log("month", month)
    // console.log("sunAboveHorizonHoursMonth", sunAboveHorizonHoursMonth)

    const sunAboveHorizonHoursAverage = sunAboveHorizonHoursMonth / daysInMonth[month - 1];

    // console.log("sunAboveHorizonHoursAverage", sunAboveHorizonHoursAverage)

    return sunAboveHorizonHoursAverage;
}