/**
 * Converts radians to degrees.
 *
 * @param {number} radians - The angle in radians.
 * @return {number} The angle in degrees.
 */
export function radToDeg(radians) {
    return radians * (180 / Math.PI)
}

/**
 * Converts degrees to radians.
 *
 * @param {number} degrees - The angle in degrees.
 * @return {number} The angle in radians.
 */
export function degToRad(degrees) {
    return degrees * (Math.PI / 180)
}

/**
 * Converts an azimuth angle to the range 0-360.
 *
 * @param {number} azimuth - The azimuth angle.
 * @return {number} The azimuth angle in the range 0-360.
 */
export function toLimits(azimuth) {
    return (azimuth + 360) % 360;
}

/**
 * Gets the view direction of a camera.
 *
 * @param {Object} cameraRef - A reference to the camera.
 * @return {Object} An object with the azimuth, altitude, and roll of the camera.
 */
export function getViewDirection(cameraRef) {
    if (!cameraRef.current) return { "azimuth": 0, "altitude": 0, "roll": 0 };
    const rotation = cameraRef.current.rotation.toArray().map(radToDeg);
    let azimuth = toLimits(90 - rotation[1])
    return { "azimuth": azimuth, "altitude": rotation[0], "roll": rotation[2] };
}