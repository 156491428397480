// version 0.1

import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './Graph.css'
import { Box } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const defaultOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: "",
        },
    },
    scales: {
        y: {
            // max: 90,
            // min: 0,
            // ticks: {
            //     stepSize: 45
            // }
            // ,
            title: {
                text: "Altitude",
                display: true,
            }
        },
        x: {
            type: 'linear',
            // max: 180,
            // min: -180,
            // ticks: {
            //     stepSize: 90,

            // },
            title: {
                text: "Azimuth",
                display: true,
            }

        }
    },

    parsing: {
        xAxisKey: 'x',
        yAxisKey: 'y'
    },
    elements: {
        point: { radius: 0 },
        line: {
            cubicInterpolationMode: 'monotone',
        }
    }
};


const defaultData = {
    datasets: [
        {
            label: 'Dataset 1',
            data: [{ x: -90, y: 0 }, { x: 0, y: 60 }, { x: 90, y: 0 }],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
    ],
};

const merge = (a, b) => [a, b].reduce((r, o) => Object
    .entries(o)
    .reduce((q, [k, v]) => ({
        ...q,
        [k]: v && typeof v === 'object' ? merge(q[k] || {}, v) : v
    }), r),
    {})

export default function Graph({ data = defaultData, options = defaultOptions }) {
    let graphOptions = merge(defaultOptions, options)
    graphOptions.parsing.xAxisKey = Object.keys(data.datasets[0].data[0])[0];
    graphOptions.scales.x.title.text = graphOptions.parsing.xAxisKey;
    graphOptions.parsing.yAxisKey = Object.keys(data.datasets[0].data[0])[1];
    graphOptions.scales.y.title.text = graphOptions.parsing.yAxisKey;

    graphOptions.maintainAspectRatio = false;


    return <Box sx={{ minHeight: "300px" }} >
        <Line className='graph' options={graphOptions} data={data} style={{ height: "100%", width: "100%" }} />
    </Box>
}
