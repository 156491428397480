import React from 'react';
import Box from '@mui/material/Box';

const imgPath = "logo_mit_text_zweihorn.png"
// const imgPath = "logo_mit_text_512.png"

const LogoBox = () => {
    return (
        <Box m={2} sx={{ maxWidth: "80%", flexShrink: 1, overflow: "auto", paddingTop: 3 }}>
            <a href="https://www.balkonsonne.app/" target="_blank" rel="noreferrer">
                <img src={imgPath} alt="Logo" style={{ maxWidth: "90%", maxHeight: "90%" }} />
            </a>
        </Box>
    );
};

export default LogoBox;
