import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Typography } from '@mui/material';

export default function SystemTable({ system, title = "System Panel" }) {

    const returnOnInvestment = (100 / system.amortizationTime).toFixed(1)

    const rows = [
        { "name": "Power", "value": system.wattage.toFixed(0), "unit": "W" },
        { "name": "Amortization Time", "value": system.amortizationTime.toFixed(1), "unit": "years" },
        { "name": "Return", "value": returnOnInvestment, "unit": "% p.a." },
    ];

    return (
        // <div style={{ paddingTop: "20px" }}>
        <TableContainer component={Paper} align="center" style={{ padding: 10 }}>
            <div style={{ paddingBottom: "10px" }}>
                <Typography>
                    {title}
                </Typography>
            </div>

            <Table sx={{ maxWidth: 300 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell><b>System Price:</b></TableCell>
                        <TableCell align="right"><b>{system.price} €</b></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.value} {row.unit}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Button variant="outlined" onClick={() => window.open("https://www.solaranlagen-portal.com/photovoltaik/kosten")} >Get a Quote!</Button>
        </TableContainer>
        // </div>
    )
}