import axios from 'axios';
import testSunshineData from './testData.json'

const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];


const requestViaFetch = async (lat, lon) => {
    const url = `https://meteostat.p.rapidapi.com/point/normals?lat=${lat}&lon=${lon}`;
    const options = {
        method: 'GET',
        headers: {
            'X-RapidAPI-Key': 'ff5e9c5759msh300055f590605bdp18b2a4jsnef7db52e1472',
            'X-RapidAPI-Host': 'meteostat.p.rapidapi.com'
        }
    };

    try {
        const response = await fetch(url, options);
        const result = await response.text();
        console.log(result)
        return result
    } catch (error) {
        console.error(error);
    }

}

const requestViaAxios = async (lat, lon) => {
    const options = {
        method: 'GET',
        url: 'https://meteostat.p.rapidapi.com/point/normals',
        // url: '/point/normals',

        params: {
            lat: lat,
            lon: lon,
        },
        headers: {
            'X-RapidAPI-Key': 'ff5e9c5759msh300055f590605bdp18b2a4jsnef7db52e1472',
            'X-RapidAPI-Host': 'meteostat.p.rapidapi.com'
        }
    };
    console.log(options)

    const response = await axios.request(options);
    return response.data.data;
}

export const getAverageSunshineHours = async ({ latitude, longitude, useTestData = false }) => {

    // console.log("getAverageSunshineHours", latitude, longitude, useTestData)

    let sunshineData = null
    if (!useTestData) {
        // sunshineData = await requestViaFetch(latitude, longitude);
        sunshineData = await requestViaAxios(latitude, longitude);
    } else {
        sunshineData = testSunshineData.data;
    }
    // console.log("sunshineData", sunshineData)

    // Calculate average sunshine hours
    let averageSunshineHoursPerMonth = Array.from({ length: 12 }, () => []);

    for (let i = 0; i < sunshineData.length; i++) {

        const { tsun, month } = sunshineData[i];
        if (!tsun) continue;
        averageSunshineHoursPerMonth[month - 1].push(tsun / 60);
        // console.log(i, tsun, month, averageSunshineHoursPerMonth[month - 1])
    }

    const averageDailySunshineHoursPerMonth = averageSunshineHoursPerMonth.map((monthData, i) => {
        const averageHoursPerMonth = monthData.reduce((sum, duration) => sum + duration, 0) / monthData.length;
        // console.log("month", month, "averageHoursPerMonth", averageHoursPerMonth)
        const avgDailyHours = averageHoursPerMonth / daysInMonth[i];
        return avgDailyHours;
    })

    return averageDailySunshineHoursPerMonth;
}