import React from 'react';
import { Grid, Typography, Box, CardMedia } from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import { yellow } from '@mui/material/colors';

// export default function SystemPresentationSection(props) {
//     const { brand, wattage, imageSrc, style } = props;
export default function SystemPresentationSection({ system, style }) {
    const { brand, wattage, imageSrc } = system;


    const topSectionSx = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: 3
    }

    const brandDisplay = () => {
        if (system.brandLogo) {
            return (
                <CardMedia component="img" src={system.brandLogo} alt="brand logo" sx={{ maxWidth: '80%', maxHeight: '90%' }} />
            )
        } else if (system.brand) {
            return (
                <Typography variant="h6">{brand}</Typography>
            )
        } else {
            return (
                <Typography variant="h6">[No brand name specified]</Typography>
            )
        }
    }

    return (<>
        {(style === "extended") && (
            <Grid item xs={4} sx={topSectionSx}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                    {brandDisplay()}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
                        <BoltIcon sx={{ color: yellow[800] }} />
                        <Typography variant="body1">{wattage} Watt</Typography>
                    </Box>
                </Box>
                <CardMedia component="img" src={imageSrc} alt="systemImage" sx={{ maxWidth: '20%', maxHeight: '20%' }} />
            </Grid>
        )}
        {(style === "compact") && (
            <Grid item xs={4} sx={topSectionSx}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {brandDisplay()}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <BoltIcon sx={{ color: yellow[800] }} />
                        <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>{wattage} W</Typography>
                    </Box>
                </Box>
                {/* <CardMedia component="img" src={imageSrc} alt="systemImage" sx={{ maxWidth: '20%', maxHeight: '20%' }} /> */}
            </Grid>
        )}
    </>
    )
}