import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    spacing: 4,

    palette: {
        primary: {
            // main: "#006700",
            main: "#FFA1F8",
        },
        secondary: {
            // main: '#F0F0EB',
            // main: '#F7BC00',
            main: '#B0FF5E',
        },
        background: {
            default: '#ffffff',
            secondary: '#d4d4d4',
        },
    },
    typography: {
        fontFamily: [
            'Open Sans',
            'Arial',
            'sans-serif',
        ].join(','),
        // color: '#000000',
    },
    shape: {
        borderRadius: 40, // Set the border radius to 50%
    },
});