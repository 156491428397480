import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useTranslation } from 'react-i18next';

export function SimpleTable(props) {

    const { yearlyBalanceValues, totalConsumption } = props;
    const { t } = useTranslation();
    const rows = []

    if (yearlyBalanceValues) {
        if (yearlyBalanceValues.yearlyYield) {
            rows.push({ "name": [t("Production")], "energy": yearlyBalanceValues.yearlyYield.toFixed(0) })
        }
        if (yearlyBalanceValues.yearlyBalance) {
            rows.push({ "name": [t("Balance")], "energy": yearlyBalanceValues.yearlyBalance.toFixed(0) })
        }
        if (totalConsumption && yearlyBalanceValues.yearlyGridConsumption) {
            rows.push({ "name": [t("Self Consumption")], "energy": (totalConsumption + yearlyBalanceValues.yearlyGridConsumption).toFixed(0) })
        }
        if (yearlyBalanceValues.yearlyFeedIn) {
            rows.push({ "name": [t("Feed In")], "energy": yearlyBalanceValues.yearlyFeedIn.toFixed(0) })
        }
        if (yearlyBalanceValues.yearlyGridConsumption) {
            rows.push({ "name": [t("Grid Use")], "energy": yearlyBalanceValues.yearlyGridConsumption.toFixed(0) })
        }
    }

    return (<Table sx={{ maxWidth: 300 }} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell><b>Yearly Values:</b></TableCell>
                <TableCell align="right"><b>Energy</b></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row) => (
                <TableRow
                    key={`TableRow-${row.name}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right">{row.energy} kWh</TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>)
}

export default function ResultTable({ yearlyBalanceValues, totalConsumption }) {

    return (
        // <div style={{ paddingTop: "20px" }}>
        <TableContainer component={Paper} align="center" style={{ padding: 10 }}>
            <div style={{ paddingBottom: "10px" }}>
                Savings per Year:
            </div>
            <span>
                <b>
                    {((totalConsumption + yearlyBalanceValues.yearlyGridConsumption) * .4).toFixed(0)} €
                </b>
                +
                <b>
                    {((yearlyBalanceValues.yearlyBalance + totalConsumption) * 0.434).toFixed(0)} kg CO₂
                </b>
            </span>

            <Accordion elevation={0} style={{ width: "100%", marginTop: 10 }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Details</Typography>
                </AccordionSummary>
                <AccordionDetails>

                    {SimpleTable({ yearlyBalanceValues, totalConsumption })}

                </AccordionDetails>
            </Accordion>



        </TableContainer>
        // </div> 
    )
}