import React, { useState, useEffect, useCallback } from 'react';
import { Button, Slider, Switch } from '@mui/material';
import DisplayResultImage from '../utils/DisplayResultImage';

const displayResult = true;

const modelUrls = ['model_wistful-hare-784_1720.h5', "model_shivering-snipe-233_380.h5"];

export default function Segmenter({ imageToPredict, maskTarget = null, setAppStatus }) {
    const [useBinaryMask, setUseBinaryMask] = useState(false);
    const [threshold, setThreshold] = useState(0.5);
    const [selectedModel, setSelectedModel] = useState(modelUrls[0]);
    const [worker, setWorker] = useState(null);
    const [tfReady, setTfReady] = useState(false);
    const [mask, setMask] = useState(null);
    const [predicting, setPredicting] = useState(false);

    const predict = useCallback(() => {
        worker.postMessage({ msg: 'predict', payload: { imageToPredict, useBinaryMask, threshold } });
        setPredicting(true);
    }, [worker, imageToPredict, useBinaryMask, threshold]);

    useEffect(() => {
        setAppStatus("loadingAI")
        const newWorker = new Worker("segmentationWorker.js");
        newWorker.postMessage({ msg: 'init', payload: { selectedModel } });
        newWorker.addEventListener('message', (e) => {
            const { msg, payload } = e.data;

            console.log("[segmentationWorker] response: ", msg, payload);

            if (msg === 'initDone') {
                setTfReady(true);
            } else if (msg === 'predictDone') {
                setMask(payload.mask);
                maskTarget && maskTarget(payload.mask);
                setPredicting(false);
                setAppStatus("idle");
            }
        });

        setWorker(newWorker);
    }, [selectedModel, maskTarget]);

    useEffect(() => {
        if (imageToPredict && worker) {
            setAppStatus("segmenting")
            predict();
        }
    }, [imageToPredict, predict, worker]);

    useEffect(() => {
        if (worker) {
            worker.postMessage({ msg: 'loadModelAndPredict', payload: { selectedModel, imageToPredict, useBinaryMask, threshold } });
            setPredicting(true);
        }
    }, [selectedModel, worker, imageToPredict, useBinaryMask, threshold]);

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", width: "100%" }}>
            <select id="model-selector" value={selectedModel} onChange={(event) => setSelectedModel(event.target.value)}>
                {modelUrls.map((model, index) => (
                    <option key={index} value={model}>{model}</option>
                ))}
            </select>

            {!tfReady && <p>Loading TensorFlow.js...</p>}

            <div>
                <Button variant="contained" color="primary" onClick={predict} disabled={predicting}>{predicting ? "In Progress..." : "Predict Sky"}</Button>
                <p>
                    <label htmlFor="binary-mask-switch">Use Binary Mask</label>
                    <Switch id="binary-mask-switch" checked={useBinaryMask} onChange={(event) => setUseBinaryMask(event.target.checked)} />
                </p>
                {useBinaryMask && (
                    <p>
                        <label htmlFor="threshold-slider">Threshold:</label>
                        <Slider id="threshold-slider" defaultValue={0.5} step={0.01} min={0} max={1} onChange={(event, value) => setThreshold(value)} />
                    </p>
                )}
            </div>

            {mask && imageToPredict && displayResult && (
                <div width={imageToPredict.width} height={imageToPredict.height}>
                    <DisplayResultImage img={mask} canvasKey="mask" />
                </div>
            )}
        </div>
    );
}