import ShadowBox from "./ShadowBox"
import "./ShadowProfiler.css"
import { useEffect, useState } from "react"
import { createEmptyShadowProfile } from "../calculationScripts/yieldCalculation"
import Button from '@mui/material/Button';


export default function ShadowProfiler({ shadowProfile, setShadowProfile }) {

    const [drawState, setDrawState] = useState("shadow")
    const [boxGrid, setBoxGrid] = useState(null)


    const refreshBoxGrid = () => {
        let newBoxGrid = []
        for (let i_row = 5; i_row >= 0; i_row--) {
            let box_row = []
            for (let i_col = 0; i_col < 24; i_col++) {
                box_row.push(<ShadowBox key={`box-${i_col}x${i_row}`} i_row={i_row} i_col={i_col} drawState={drawState} shadowProfile={shadowProfile} setShadowProfile={setShadowProfile} />)
            }
            newBoxGrid.push(<div key={`row-${i_row}`} className="box-row">{box_row}</div>)
        }
        newBoxGrid.push(<div key={`row-labels`} className="box-row">{["east", "south", "west"].map((dir) =>
            <span key={`row-span-${dir}`} className="dir-label">{dir}</span>
        )}</div>)
        setBoxGrid(newBoxGrid)
    }

    useEffect(() => {
        refreshBoxGrid()
    }, [shadowProfile])

    const handleReset = () => {
        console.log("resetted shadow profile");
        let emptyShadowProfile = createEmptyShadowProfile();
        setShadowProfile(emptyShadowProfile)
    }

    const handleSet = () => {
        let sp = new Array(24).fill(0).map(() => new Array(6).fill(1))
        setShadowProfile(sp)
    }

    return (
        <div className="container">
            Manual Shadow Profile:
            <div style={{ padding: "5px" }}>
                {boxGrid && boxGrid}
            </div>
            {/* <button ></button> */}
            <Button onClick={handleReset} variant="outlined">reset shadow profile</Button>

        </div>

    )
}