import React from 'react';
import calculateSunshinePercentage from './calculateSunshinePercentage';
import { Accordion } from '@mui/material';
import { AccordionSummary } from '@mui/material';
import { AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@mui/material';

const startDate = '2015-01-01';
const endDate = '2022-12-31';

const locations = {
    Berlin: {
        latitude: 52.52,
        longitude: 13.4
    },
    Sydney: {
        latitude: -33.8688,
        longitude: 151.2093
    },
    Tenerife: {
        latitude: 28.2916,
        longitude: -16.6291
    }
};

let useTestData = false;

export default class SunFromWeatherApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            avgSunHrs: null,
            potSunHrs: null,
            sunPerc: null,
            loading: false,
            error: null,
            location: this.props.location || locations.Berlin,
            setWeatherProfile: this.props.setWeatherProfile,
            setAppStatus: this.props.setAppStatus,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        this.setState({ setAppStatus: "loadingWeather" })
        this.getData();

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.location !== prevState.location) {
            this.getData();
        }
    }

    getData = async () => {
        if (this.state.location === locations.Berlin) {
            useTestData = true;
        } else {
            useTestData = false;
        }

        const { sunPerc, avgSunHrs, potSunHrs } = await calculateSunshinePercentage(this.state.location.latitude, this.state.location.longitude, useTestData)
        this.setState({ sunPerc: sunPerc, avgSunHrs: avgSunHrs, potSunHrs: potSunHrs, loading: false });
        this.setState({ setAppStatus: "idle" });
        this.state.setWeatherProfile(sunPerc)
    }

    render() {
        const { loading, error, sunPerc, avgSunHrs, potSunHrs } = this.state;
        if (loading) {
            return <div>Loading...</div>;
        }
        if (error) {
            return <div>{error.message}</div>;
        }
        return (
            <div >

                {/* <img src="logo_mit_text_512.png" alt="logo" style={{ width: 200, height: 200 }} />
                
                <h1>Proof of Concept for Module 6: Weather API access</h1>
                
                <select onChange={(e) => this.setState({ location: locations[e.target.value] })}>
                    {Object.keys(locations).map((location, index) => (
                        <option key={index} value={location}>{location}</option>
                    ))}
                </select> */}

                {sunPerc && avgSunHrs && potSunHrs && (
                    <Accordion style={{ width: "100%" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Show weather data</Typography>
                        </AccordionSummary>
                        <AccordionDetails>



                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: "center" }} >
                                {sunPerc.map((sunPerc, index) => (
                                    <div key={index} style={{ border: "1px solid white", margin: 10 }}>
                                        <b>Month {index + 1}</b>
                                        <p>Sunshine percentage: {sunPerc.toFixed(0)}%</p>
                                        <p>Average sunshine hours: {avgSunHrs[index].toFixed(1)}</p>
                                        <p>Potential sunshine hours: {potSunHrs[index].toFixed(1)} </p>
                                    </div>
                                ))}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )}
            </ div>
        );
    };
}

export async function loadTestWeatherData() {
    const { sunPerc, avgSunHrs, potSunHrs } = await calculateSunshinePercentage(locations.Berlin.latitude, locations.Berlin.longitude, true)

    return sunPerc

}