import React from 'react';
import { Grid, useTheme } from '@mui/material';
import Co2Icon from '@mui/icons-material/Co2';
import SavingsIcon from '@mui/icons-material/Savings';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import IconItemBox from './IconItemBox';
import { useTranslation } from 'react-i18next';

export default function SavingsPerYearGrid(props) {
    const { t } = useTranslation();
    const { savingsEuro, savingsCO2, amortizationTime, returnRate } = props;
    const theme = useTheme();

    const sectionSx = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        // borderRight: `1px solid ${theme.palette.background.secondary}`,
        // '&:last-child': {
        //     borderRight: 'none'
        // },
        padding: 3
    }

    const iconSize = 50;

    return (
        <Grid container spacing={4} sx={{ paddingTop: 3, paddingBottom: 3 }}>
            <IconItemBox
                icon={<Co2Icon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={savingsCO2.toFixed(0)}
                unit="kg"
                tooltip={t("co2savings tooltip")} />
            <IconItemBox
                icon={<SavingsIcon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={savingsEuro.toFixed(0)}
                unit="€"
                tooltip={t("moneysavings tooltip")} />
            <IconItemBox
                icon={<HourglassEmptyIcon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={amortizationTime.toFixed(1)}
                unit={t("years")}
                tooltip={t("paysoffafter tooltip")} />
            <IconItemBox
                icon={<TrendingUpIcon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={returnRate.toFixed(1)}
                unit="% p.a."
                tooltip={t("returns tooltip")} />
        </Grid >
    )
}
