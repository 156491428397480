import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, Card, CardContent, CardMedia, Typography, Button, Grid, Tooltip } from "@mui/material"

export default function IconItemBox(props) {
    const { icon, value, unit, tooltip } = props;
    const theme = useTheme();

    const sectionSx = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        // borderRight: `1px solid ${theme.palette.background.secondary}`,
        // '&:last-child': {
        //     borderRight: 'none'
        // },
        padding: 3,
        // bgColor: theme.palette.primary.light

    }


    return (
        <Grid item xs={6} sx={sectionSx}>
            <Tooltip title={tooltip} enterTouchDelay={0} leaveDelay={1000} placement="top">
                <div >
                    {icon}
                    {props.heading &&
                        <Typography variant="body1">{props.heading}:</Typography>
                    }
                    <Typography variant="body1"
                        sx={(props.heading != undefined) ? { fontWeight: 'bold' } : {}}
                    >{value} {unit}</Typography>
                </div>
            </Tooltip>
        </Grid>
    )
}