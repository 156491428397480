import { Opacity } from "@mui/icons-material"

export default function CoverScreen({ zIndex, children, transparent = false, justify = "space-around" }) {

    const outerDivStyle = {
        position: "absolute",
        height: "100vh",
        width: "100%",
        backgroundColor: !transparent ? "white" : "transparent",
        color: "black",
        zIndex: zIndex,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    }

    const innerDivStyle = {
        width: "80%",
        height: "80%",
        textAlign: "center",
        display: "flex",
        justifyContent: justify,
        alignItems: "center",
        flexDirection: "column"
    }

    return <div style={outerDivStyle} >
        <div style={innerDivStyle}>
            {children}
        </div>
    </div>
}