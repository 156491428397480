// en.js
export default {
    "Language Select": "Zu Deutsch wechseln",
    "How does it work": "How does it work?",
    "panoramaExplanation": 'Scan the sky from your balcony. The sensors in your phone provide accurate data about location and orientation.',
    "calculateExplanation": "The AI automatically calculates the best orientation and system size. Accurate weather data is retrieved via your location.",
    "resultsExplanation": 'See immediately how much CO₂ and € you can save with your own small system.',
    "First we need some permissions": "First we need some permissions",
    "Orientation access": "We need the orientation of your device to create the panorama image.",
    "Location access": "We need your location to calculate the local solar radiation, weather etc.",
    "Camera access": "We need your camera to take images of the sky.",
    "Next": "Next",
    "Use Test Data": "Use Test Data",
    "readyForProcessing": "Ready for processing",
    "creatingPanorama": "Creating panorama...",
    "loadingAI": "Loading AI...",
    "segmenting": "Analyzing shadows...",
    "loadingWeather": "Loading local weather data...",
    "calculatingBestSystem": "Finding best system...",
    "resultsReady": "Results ready!",
    "showResults": "Show Results",
    "Congrats": "Congratulations!",
    "CongratsText": "A balcony solar system is worth it for you:",
    "Other Systems": "Other Systems",
    "Order Now": "Order Now",
    "Order": "Order",
    "years": " years",
    "co2savings tooltip": "This is how much CO₂ your system will save per year! For relation: a tree binds about 25kg CO₂ per year.",
    "moneysavings tooltip": "This is how much money you will save per year!",
    "paysoffafter tooltip": `After this much time, the system has paid for itself. Tipical lifespan: more than 25 years!`,
    "returns tooltip": `This is how much return you get on your money. For comparison: on a typical savings account it's only 4%.`,
    "Powered by": "by ",
    "init panorama": "Initializing panorama creator...",
    "calibrate compass": "Move and tilt your phone in a figure eight to calibrate the compass.",
    "Go to balcony": "Go to your balcony...",
    "I am there": "I am there!",
    "Get ready 1": "In the next step, we will scan the sky.",
    "Get ready 2": "Stand at the front of your balcony and look straight ahead.",
    "look at arrows": "Look with the camera in the direction of the arrows.",
    "positions pending": "position(s) to check",
    "permissions blocked warning": "One of the permissions is denied. The app cannot work without them. If you were not prompted to give permissions, maybe your browser is blocking access. You can try to reload the page or check the browser settings to grant permissions.",
    "kg / a": "kg / year",
    "€ / a": "€ / year",
    "LongRun": "Long-term worth it!",
    "LongRunText": "A balcony solar system is worth it for you in the long run. For the environment, in any case!",
    "NotWorth": "Not worth it.",
    "NotWorthText": "A balcony solar system is not worth it for you until a very long time.",
    "production": "Production in %",
    "optimalSolarSystem": "The optimal solar system for your balcony will produce {{yearlyYield}} kWh of electricity per year. At a price of {{electricityPrice}} € per kWh, this electricity is worth {{electricityWorth}} €.",
    "calc explanation": "The AI analyzes the sky view from your balcony. Along with accurate local weather data it is possible to calculate the exact power profile and compare it to a typical German household consumption profile of 2000 kWh per year.",



    // other translations...
};