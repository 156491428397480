import React from 'react'
import { Grid, Box, Typography } from '@mui/material'


const sectionSx = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    textAlign: 'center',
    // borderRight: `1px solid ${theme.palette.background.secondary}`,
    // '&:last-child': {
    //     borderRight: 'none'
    // },
    padding: 3,
}

export default function IconTextSection({ content }) {

    const image = (image) => {
        if (typeof image === 'string') {
            return <img src={image} alt="explanatoryImage" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        } else {
            return image
        }
    }

    return (
        <Grid item xs={4} sx={sectionSx}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: "60%", maxWidth: 100, padding: 3 }}>
                {/* <CardMedia component="img" src={content.image} alt="explanatoryImage" sx={{ maxWidth: '20%', maxHeight: '20%' }} /> */}
                {image(content.image)}
            </Box>
            <Typography variant="body2">{content.text}</Typography>
        </Grid>
    )
}
