import React from 'react';
import { Box, Typography, Card, Button } from '@mui/material';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';


export default function PanoramaImagesDisplay({ panoramaImages }) {

    const downloadPanoramaImages = (panoramaImages) => {
        const zip = new JSZip();

        const promises = panoramaImages.map((image, index) => {
            return new Promise((resolve) => {
                // Convert imageData to blob
                const canvas = document.createElement('canvas');
                canvas.width = image.imageData.width;
                canvas.height = image.imageData.height;
                const ctx = canvas.getContext('2d');
                ctx.putImageData(image.imageData, 0, 0);
                canvas.toBlob(blob => {
                    // Add image to zip
                    zip.file(`image${index}.png`, blob);

                    // Add viewDirection to zip
                    const viewDirectionBlob = new Blob([JSON.stringify(image.viewDirection)], { type: 'application/json' });
                    zip.file(`viewDirection${index}.json`, viewDirectionBlob);

                    resolve();
                });
            });
        });

        Promise.all(promises).then(() => {
            zip.generateAsync({ type: "blob" })
                .then(blob => {
                    saveAs(blob, "images.zip");
                });
        });
    }

    return <div>

        <Typography variant="h6" align="center" style={{ paddingTop: 5 }}>
            Panorama Bilder:
        </Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>

            {panoramaImages.map((image, index) => {
                return <Card key={`pano${index}`} style={{ width: "25%", height: "auto", maxWidth: 200, maxHeight: 200, margin: '10px', borderRadius: "5px" }}>
                    <img key={`img${index}`} style={{ width: '100%', maxHeight: '80%', objectFit: 'fit' }} src={image.imageUrl} alt="panorama" />
                    {image.viewDirection && <>
                        <Typography variant="body2">
                            azi: {image.viewDirection.azimuth.toFixed(0)}° /
                            alt: {image.viewDirection.altitude.toFixed(0)}°
                            {/* <p key={`azi${index}`}>azi: {image.viewDirection.azimuth.toFixed(0)}°</p>
                            <p key={`alti${index}`}>alt: {image.viewDirection.altitude.toFixed(0)}°</p> */}
                            {/* <p key={`roll${index}`}>roll: {image.viewDirection.roll.toFixed(0)}</p> */}
                        </Typography>
                    </>
                    }
                </Card>
            })}
        </div>
        <Button variant="contained" onClick={() => {
            downloadPanoramaImages(panoramaImages)
        }
        }>Download</Button>
    </div>
}