import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en";
import deTranslations from "./locales/de";
import frTranslations from "./locales/fr";

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: enTranslations
            },
            de: {
                translation: deTranslations
            },
            fr: {
                translation: frTranslations
            }
        },
        lng: "de", // language to use, more languages can be added later
        interpolation: {
            escapeValue: false
        }
    });