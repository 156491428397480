import { useState, useEffect, useRef } from 'react'
import ARCanvas from './components/M1CreatePanorama/AR/ARCanvas';
import FovCalculator from './components/M1CreatePanorama/FovCalculator';
import { loadTestImages } from './components/utils/utils'
import { CreatePanorama, createPanorama } from './components/M1CreatePanorama/CreatePanorama';
import { Button } from '@mui/material';
import Segmenter from './components/M2Segmentation/Segmenter';
import MaskToShadowProfile from './components/utils/MaskToShadowProfile';
import SunFromWeatherApi, { loadTestWeatherData } from './components/M6WeatherAPI/SunFromWeatherApi';
import Calculator from './components/M7Calculator/Calculator';
import ModulePage from './components/utils/ModulePage';
import HelloScreen from './components/Screens/HelloScreen';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import ResultScreen from './components/Screens/ResultScreen';
import Box from '@mui/material/Box';
import defaultSystemsToCompare from './components/systemsToCompare';
import DisplayResultImage from './components/utils/DisplayResultImage';
import PanoramaImagesDisplay from './components/Screens/ProcessingScreenComponents/PanoramaImagesDisplay';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import RequestPermissionsScreen from './components/Screens/RequestPermissionsScreen';
import './i18n/i18n';
import { useTranslation } from 'react-i18next';
import GoToBalconyScreen from './components/Screens/GoToBalconyScreen';
import { createEmptyShadowProfile } from "./components/M7Calculator/calculationScripts/yieldCalculation"
import { detectHostPlatform } from './components/utils/utils';

const loadTestImagesOnInit = false
const testImagesPath = [
  "test_images/Alfred/Grey-Snowy",
  "test_images/Alfred/Sunny-Bad-Resolution",
  "test_images/Alfred/Alfred-Kaestner-Sonne"
][2]

const verbose = false


// dev
const dev = false
// const initialScreen = "resultsReady"
const initialScreen = "takePanoramaImages"
// const startScreen = "readyForProcessing"


export default function App() {
  const { t } = useTranslation();
  const [panoramaImages, setPanoramaImages] = useState([])
  const [stitchedPanorama, setStitchedPanorama] = useState(null);
  const [segmentationMask, setSegmentationMask] = useState(null);
  const [shadowProfile, setShadowProfile] = useState(dev ? createEmptyShadowProfile() : null);
  const [weatherProfile, setWeatherProfile] = useState(dev ? loadTestWeatherData() : null);
  const [openCVReady, setOpenCVReady] = useState(false);
  const [worker, setWorker] = useState(null);
  const [appStatus, setAppStatus] = useState(dev ? initialScreen : "init");
  const [systemsToCompare, setSystemsToCompare] = useState(defaultSystemsToCompare);
  const [bestSystem, setBestSystem] = useState(defaultSystemsToCompare[0])
  const [balconyAzimuth, setBalconyAzimuth] = useState(dev ? 180 : null)
  const [graphData, setGraphData] = useState({ "production": null, "consumption": null, "balance": null })
  const [yearlyBalanceValues, setYearlyBalanceValues] = useState(null)
  const [location, setLocation] = useState(null)
  const [cameraStream, setCameraStream] = useState(null)


  const [calcScreenTabStatus, setCalcScreenTabStatus] = useState(0);

  const startScreen = useRef(null)
  const panoramaScreen = useRef(null)
  const calculatorScreen = useRef(null)
  const resultScreen = useRef(null)

  useEffect(() => {
    // init service worker and opencv
    const newWorker = new Worker("worker.js");
    newWorker.postMessage({ msg: 'loadOpenCV' });
    newWorker.addEventListener('message', (e) => {
      const { msg, payload } = e.data;

      if (msg === 'openCVLoaded') {
        console.log(payload)
        setOpenCVReady(true);
      }
    })

    setWorker(newWorker)

    if (loadTestImagesOnInit) {
      loadTestImagesCall()
    }
  }, [])

  const loadTestImagesCall = () => {
    loadTestImages(testImagesPath).then((result) => {
      verbose && console.log("result", result)
      setPanoramaImages(result.panoramaImages)
      setAppStatus("readyForProcessing")
      // if (result.testMask) {
      //   setSegmentationMask(result.testMask)
      // }
    })
  }


  useEffect(() => {
    verbose && console.log("[App] weatherProfile changed: ", weatherProfile);
  }, [weatherProfile])


  const appStatusDisplay = () => {
    let text = ""
    console.log("[App] appStatus: ", appStatus)
    switch (appStatus) {
      case "initializing":
        text = "Starte App..."
        break;
      case "requestPermissions":
        text = "requestPermissions..."
        break;
      case "takePanoramaImages":
        text = "Nimm die Bilder auf..."
        break;
      case "loadingTestData":
        text = "Lade Testdaten..."
        break;
      case "readyForProcessing":
        text = t("readyForProcessing")
        break;
      case "creatingPanorama":
        text = t("creatingPanorama")
        break;
      case "loadingAI":
        text = t("loadingAI")
        break;
      case "segmenting":
        text = t("segmenting")
        break;
      case "loadingWeather":
        text = t("loadingWeather")
        break;
      case "calculatingBestSystem":
        text = t("calculatingBestSystem")
        break;
      case "resultsReady":
        text = t("resultsReady")
        break;
      default:
        return <></>
    }
    return <>
      {appStatus !== "readyForProcessing" && appStatus !== "resultsReady" &&
        <img src="loading.gif" alt="loading" />
      }
      <Typography variant="h5" component="h2" gutterBottom>
        {text}
      </Typography>
      {appStatus === "resultsReady" &&
        <Button variant="contained" color="primary" sx={{ padding: 3, margin: 10 }} onClick={() => { setAppStatus("showResults") }}>{t("showResults")}</Button>
      }
    </>
  }

  // useEffect(() => {
  //   startScreen.current.scrollIntoView({ behavior: 'smooth' });
  //   // document.body.style.overflow = 'hidden';

  //   // return () => {
  //   //   document.body.style.overflow = 'unset';
  //   // };
  // }, []);

  detectHostPlatform()

  useEffect(() => {
    if (appStatus === "loadingTestData") loadTestImagesCall();
    if (appStatus === "creatingPanorama") createPanorama(panoramaImages, worker, setStitchedPanorama, setAppStatus);

  }, [appStatus]);

  useEffect(() => {
    verbose && console.log("[App] bestSystem: ", bestSystem);
  }, [bestSystem]);

  return (<>
    <ThemeProvider theme={theme}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>

        {(appStatus === "init") &&
          <ModulePage reference={startScreen} height="110vh">
            <HelloScreen setAppStatus={setAppStatus} />
          </ModulePage>
        }

        {(appStatus === "requestPermissions") &&
          <ModulePage reference={startScreen}>
            <RequestPermissionsScreen setAppStatus={setAppStatus} setLocation={setLocation} setCameraStream={setCameraStream}/>
          </ModulePage>
        }

        {(appStatus === "goToBalcony") &&
          <ModulePage reference={startScreen}>
            <GoToBalconyScreen setAppStatus={setAppStatus} />
          </ModulePage>
        }

        {!stitchedPanorama && (appStatus === "takePanoramaImages") &&
          <ModulePage reference={panoramaScreen} height="100vh">
            {/* <div style={{ height: "80vh" }}> */}
            <ARCanvas
              panoramaImages={panoramaImages}
              setPanoramaImages={setPanoramaImages}
              scrollToRef={calculatorScreen}
              setStitchedPanorama={setStitchedPanorama}
              setBalconyAzimuth={setBalconyAzimuth}
              setAppStatus={setAppStatus}
              worker={worker}
              cameraStream={cameraStream}
            />
            {/* </div> */}
          </ModulePage>
        }

        {(appStatus !== "showResults" && appStatus !== "init" && appStatus !== "takePanoramaImages" && appStatus !== "requestPermissions" && appStatus !== "goToBalcony") &&
          // {/* {(appStatus !== "resultsReady" && appStatus !== "init") && */}
          <ModulePage reference={calculatorScreen} height={"auto"}>
            {/* <ProcessingScreen appStatus={appStatus} /> */}

            {appStatusDisplay()}

            {appStatus === "readyForProcessing" &&
              <Button variant="contained" color="primary" sx={{ padding: 3, margin: 10 }} onClick={() => {
                setAppStatus("creatingPanorama");
              }
              }>{t("Next")}</Button>
            }

            <Accordion sx={{ maxWidth: "90%", display: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box flexGrow={1}>
                  <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                    Details
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>

                <Tabs value={calcScreenTabStatus} onChange={(_, n) => setCalcScreenTabStatus(n)} sx={{ marginBottom: 5 }} variant="scrollable"
                  scrollButtons="auto">
                  <Tab label="Panorama" />
                  <Tab label="Segmentierung" />
                  <Tab label="Projektion" />
                  <Tab label="Wetter" />
                  <Tab label="Berechung" />
                </Tabs>

                <Box hidden={calcScreenTabStatus !== 0}>
                  {panoramaImages && !stitchedPanorama &&
                    <PanoramaImagesDisplay panoramaImages={panoramaImages} />}

                  {stitchedPanorama && <DisplayResultImage img={stitchedPanorama} canvasKey="panorama" />}
                </Box>


                <Box hidden={calcScreenTabStatus !== 1}>
                  {stitchedPanorama && <div style={{ width: "100%" }}>
                    <Segmenter imageToPredict={stitchedPanorama} maskTarget={setSegmentationMask} setAppStatus={setAppStatus} />
                  </div>}
                </Box>

                <Box hidden={calcScreenTabStatus !== 2}>
                  {segmentationMask && <div style={{ width: "100%" }}>
                    <MaskToShadowProfile mask={segmentationMask} panoramaImages={panoramaImages} setShadowProfile={setShadowProfile} setAppStatus={setAppStatus} />
                  </div>}
                </Box>

                <Box hidden={calcScreenTabStatus !== 3}>
                  {shadowProfile && <div style={{ width: "100%" }}>
                    {/* currently using Berlin data only */}
                    <SunFromWeatherApi setWeatherProfile={setWeatherProfile} setAppStatus={setAppStatus} />
                  </div>}
                </Box>

                <Box hidden={calcScreenTabStatus !== 4}>
                  {shadowProfile && weatherProfile && <div style={{ width: "100%" }}>
                    <Calculator inputShadowProfile={shadowProfile} inputWeatherProfile={weatherProfile}
                      setAppStatus={setAppStatus} systemsToCompare={systemsToCompare}
                      setSystemsToCompare={setSystemsToCompare} bestSystem={bestSystem} setBestSystem={setBestSystem} balconyAzimuth={balconyAzimuth} graphData={graphData} setGraphData={setGraphData} yearlyBalanceValues={yearlyBalanceValues} setYearlyBalanceValues={setYearlyBalanceValues} />
                  </div>}
                </Box>

              </AccordionDetails>
            </Accordion>
          </ModulePage>
        }

        {(appStatus === "showResults") &&
          <ModulePage reference={resultScreen} height="auto">
            <ResultScreen bestSystem={bestSystem} systems={systemsToCompare} graphData={graphData} yearlyBalanceValues={yearlyBalanceValues} location={location} />
          </ModulePage>
        }

        {/* <div>
        <Button variant="contained" color="primary" onClick={loadTestImagesCall}>load test images</Button>
      </div> */}


        {/* {openCVReady && worker && panoramaImages && (panoramaImages.length > 2) && <div style={{ width: "100%" }}>
        <FovCalculator panoramaImages={panoramaImages} worker={worker} />
        <CreatePanorama panoramaImages={panoramaImages} worker={worker} stitchedPanorama={stitchedPanorama} setStitchedPanorama={setStitchedPanorama} />
      </div>} */}





      </div>
    </ThemeProvider >
  </>
  )
}
