import { useEffect, useState } from "react"
import DisplayResultImage from "./DisplayResultImage"
import { Box } from '@mui/system';
import { Container, Typography } from "@mui/material";


const shadowValue = 1
const noShadowValue = 0

const cameraFieldOfView = 70 // degrees

const calcSectorShadow = (mask, xrange, yrange) => {
    // console.log("[calcSectorShadow] xrange: ", xrange, " yrange: ", yrange)
    const width = mask.width;
    const height = mask.height;

    // console.log("[calcSectorShadow] mask dims: ", mask.width, mask.height)
    console.log("[calcSectorShadow] xrange: ", xrange, " yrange: ", yrange)

    let sky = 0;
    let total = 0;
    for (let y = yrange[0]; y < yrange[1]; y++) {
        if (y >= height || y < 0) {
            total++;
            continue
        }
        for (let x = xrange[0]; x < xrange[1]; x++) {
            if (x >= width || x < 0) {
                total++;
                continue
            }
            const index = (-y * mask.width + x) * 4;

            try {
                const pixel = Array.from(mask.data.slice(index, index + 4));
                if (pixel[0] === 0) {
                    sky++;
                }
                total++;
            } catch (error) {
                console.log("error: ", error)
                console.log("index: ", index)
                console.log("mask.data: ", mask.data)
            }
            // total++;
        }
    }

    const result = sky / total
    console.log("[calcSectorShadow] result: ", result)

    return result;
}

const arrayToImage = (array, aboveHorizonOnly = true) => {

    const width = array.length;
    let height = array[0].length;
    // console.log("[arrayToImage] width: ", width, " height: ", height)

    let flattenedData = [];

    const y_range = []
    if (aboveHorizonOnly) {
        y_range[0] = Math.round(height / 2);
        y_range[1] = height;
    }

    for (let y = height - 1; y >= 0; y--) {

        for (let x = 0; x < width; x++) {
            const pixelValue = Array.from({ length: 3 }, () => Math.round((1 - array[x][y]) * 255))
            // console.log("[arrayToImage] pixelValue: ", pixelValue)
            flattenedData.push(...pixelValue, 255);

        }
    }

    return new ImageData(new Uint8ClampedArray(flattenedData), width, height);
}

function radToDeg(radians) {
    return radians * (180 / Math.PI);
}

function degToRad(degrees) {
    return degrees * (Math.PI / 180);
}

const calculatePanoramaFov = (panoramaImages) => {
    const imgHorizontalFov = cameraFieldOfView
    const imgVerticalFov = cameraFieldOfView

    let maxAltitude = null
    let minAltitude = null
    let maxAzimuth = null
    let minAzimuth = null

    panoramaImages.forEach(img => {
        const azi = img.viewDirection.azimuth
        const alti = img.viewDirection.altitude

        const imgVerticalAov = imgVerticalFov
        // const imgHorizontalAov = radToDeg(2 * Math.tan(degToRad(FoV / 2))) ;
        const imgHorizontalAov = imgHorizontalFov / Math.cos(degToRad(alti))

        const imgAzimuthRange = [azi - imgHorizontalAov / 2, azi + imgHorizontalAov / 2]
        const imgAltitudeRange = [alti - imgVerticalAov / 2, alti + imgVerticalAov / 2]

        if (minAzimuth === null || imgAzimuthRange[0] < minAzimuth) minAzimuth = imgAzimuthRange[0]
        if (maxAzimuth === null || imgAzimuthRange[1] > maxAzimuth) maxAzimuth = imgAzimuthRange[1]
        if (minAltitude === null || imgAltitudeRange[0] < minAltitude) minAltitude = imgAltitudeRange[0]
        if (maxAltitude === null || imgAltitudeRange[1] > maxAltitude) maxAltitude = imgAltitudeRange[1]
    })

    const panoramaAzimuthRange = [minAzimuth, maxAzimuth]
    const panoramaAltitudeRange = [minAltitude, maxAltitude]

    return [panoramaAzimuthRange, panoramaAltitudeRange]
}


export default function MaskToShadowProfile({ mask, panoramaImages, setShadowProfile, assumeNoShadowAboveMaskRange = true }) {
    const [shadowProfileImage, setShadowProfileImage] = useState(null)
    const [maskAzimuthRange, setMaskAzimuthRange] = useState(null)
    const [maskAltitudeRange, setMaskAltitudeRange] = useState(null)

    useEffect(() => {
        calculateShadowProfile()
    }, [mask, panoramaImages])

    const calculateShadowProfile = () => {

        const [maskAzimuthRange, maskAltitudeRange] = calculatePanoramaFov(panoramaImages)

        setMaskAzimuthRange(maskAzimuthRange)
        setMaskAltitudeRange(maskAltitudeRange)



        const xPixelPerDegree = mask.width / (maskAzimuthRange[1] - maskAzimuthRange[0])
        const yPixelPerDegree = mask.height / (maskAltitudeRange[1] - maskAltitudeRange[0])

        // console.log("xPixelPerDegree: ", xPixelPerDegree, " yPixelPerDegree: ", yPixelPerDegree)

        const azimuthResolution = 15 // degrees
        const altitudeResolution = 15 // degrees

        let shadowProfile = Array.from({ length: 360 / azimuthResolution },
            () => Array.from({ length: 180 / altitudeResolution }, () => shadowValue))

        // console.log("[MaskToShadowProfile] mask array:", imageToArray(mask))
        // console.log("[MaskToShadowProfile] maskAzimuthRange: ", maskAzimuthRange, " maskAltitudeRange: ", maskAltitudeRange)
        // console.log("mask width: ", mask.width, " mask height: ", mask.height)

        // console.log("shadowProfile x & y dim", shadowProfile.length, shadowProfile[0].length)

        for (let ix = 0; ix < shadowProfile.length; ix++) {
            // let iy = 0
            for (let iy = 0; iy < shadowProfile[ix].length; iy++) {
                const shadowProfileAzimuthRange = [ix, (ix + 1)].map(x => Math.floor(x * azimuthResolution)) // in degrees
                const shadowProfileAltitudeRange = [iy, (iy + 1)].map(y => Math.floor(y * altitudeResolution) - 90) // in degrees



                // check if the pixel range is above the mask range and if yes, set the shadow profile value to 1
                // console.log(shadowProfileAltitudeRange[0] > maskAltitudeRange[1], shadowProfileAltitudeRange[1] > maskAltitudeRange[1])

                if (assumeNoShadowAboveMaskRange && shadowProfileAltitudeRange[0] > maskAltitudeRange[1] && shadowProfileAltitudeRange[1] > maskAltitudeRange[1]) {
                    shadowProfile[ix][iy] = noShadowValue
                    continue
                }

                // check if the pixel range is within the mask range
                // if not, set the shadow profile value to 0
                if ((shadowProfileAzimuthRange[0] < maskAzimuthRange[0] && shadowProfileAzimuthRange[1] < maskAzimuthRange[0])
                    || (shadowProfileAzimuthRange[0] > maskAzimuthRange[1] && shadowProfileAzimuthRange[1] > maskAzimuthRange[1])) {
                    // console.log("sector azimuth out of mask range")
                    continue
                }
                if ((shadowProfileAltitudeRange[0] < maskAltitudeRange[0] && shadowProfileAltitudeRange[1] < maskAltitudeRange[0])
                    || (shadowProfileAltitudeRange[0] > maskAltitudeRange[1] && shadowProfileAltitudeRange[1] > maskAltitudeRange[1])) {
                    // console.log("sector altitude out of mask range")
                    continue
                }

                // console.log("[MaskToShadowProfile] shadowProfileAzimuthRange: ", shadowProfileAzimuthRange)
                // console.log("[MaskToShadowProfile] shadowProfileAltitudeRange: ", shadowProfileAltitudeRange)

                let xmin = Math.floor((shadowProfileAzimuthRange[0] - maskAzimuthRange[0]) * xPixelPerDegree)
                let xmax = Math.floor((shadowProfileAzimuthRange[1] - maskAzimuthRange[0]) * xPixelPerDegree)
                let ymin = Math.floor((shadowProfileAltitudeRange[0] - maskAltitudeRange[0]) * yPixelPerDegree)
                let ymax = Math.floor((shadowProfileAltitudeRange[1] - maskAltitudeRange[0]) * yPixelPerDegree)

                const xRange = [xmin, xmax]
                const yRange = [ymin, ymax]

                // console.log("shadowProfileAzimuthRange: ", shadowProfileAzimuthRange, " shadowProfileAltitudeRange: ", shadowProfileAltitudeRange)
                // console.log("xmin: ", xmin, " xmax: ", xmax, " ymin: ", ymin, " ymax: ", ymax)

                shadowProfile[ix][iy] = calcSectorShadow(mask, xRange, yRange)
            }

        }

        // slice shadow profile to altitude of 0-90 degrees
        let newShadowProfile = []
        for (let ix = 0; ix < shadowProfile.length; ix++) {
            newShadowProfile.push(shadowProfile[ix].slice(shadowProfile[ix].length / 2))
        }

        shadowProfile = newShadowProfile

        console.log("[MaskToShadowProfile] shadowProfile: ", shadowProfile)

        setShadowProfile(shadowProfile)
        setShadowProfileImage(arrayToImage(shadowProfile))

    }

    return (
        <Container>

            <Typography >
                MaskToShadowProflie
            </Typography>
            {maskAzimuthRange && maskAltitudeRange &&
                <Typography >
                    maskAzimuthRange: [{maskAzimuthRange[0].toFixed(0)}, {maskAzimuthRange[1].toFixed(0)}], maskAltitudeRange: [{maskAltitudeRange[0].toFixed(0)}, {maskAltitudeRange[1].toFixed(0)}]
                </Typography>
            }
            {shadowProfileImage && <div>
                <DisplayResultImage img={shadowProfileImage} canvasKey="shadowProfile" />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>
                        <Typography >
                            North
                        </Typography>
                    </span>
                    <span>
                        <Typography >
                            East
                        </Typography>
                    </span>
                    <span>
                        <Typography >
                            South
                        </Typography>
                    </span>
                    <span>
                        <Typography >
                            West
                        </Typography>
                    </span>
                    <span>
                        <Typography >
                            North
                        </Typography>
                    </span>
                </div>
            </div>
            }
        </Container>

    )
}

