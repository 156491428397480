import { toLimits } from '../../M1CreatePanorama/AR/utils'

// Functions to calculate the yield of a solar panel setup
const { getSunPos } = require('./sunPositionCalc')

const verbose = false

let defaultOrientation = { "azimuth": 0, "tilt": 0 }
let defaultLocation = { "longitude": 0, "latitude": 0 }
const defaultWeatherProfile = Array(12).fill(100)

export const radians = (degrees) => (Math.PI / 180) * degrees;

export const atmosphericCorrection = (sun_elevation) => {
    if (sun_elevation < 0) {
        return 0
    } else {
        return Math.exp(-1 / Math.sin(radians(sun_elevation))) * Math.exp(1);
    }
}

export const createEmptyShadowProfile = (options = null) => {
    // Shadow profile is an array of 24 hours (0-360 deg) and 6 rows (0-90 deg) for each 15 degrees of azimuth and elevation.
    // The values represent the percentage of the solar irradiance that is not blocked by shadows. 1 means no shadow, 0 means full shadow.
    let shadowProfile = [];
    for (let h = 0; h < 24; h++) {
        shadowProfile[h] = [];
        for (let j = 0; j < 6; j++) {
            if (options === "morning shadow" && h < 12) {
                shadowProfile[h][j] = 1;
            } else if (options === "evening shadow" && h > 12) {
                shadowProfile[h][j] = 1;
            } else {
                shadowProfile[h][j] = 0;
            }
        }
    }
    return shadowProfile;
}

export const shadowCorrection = (sun_elevation, sun_azimuth, shadowProfile) => {
    // shadow value of 0 means no shadow, 1 means full shadow
    if (sun_azimuth < 0 || sun_azimuth > 360) {
        console.warn("sun_azimuth out of range")
    }
    if (sun_elevation < -90 || sun_elevation > 90) {
        console.warn("sun_elevation out of range")
    }
    if (sun_elevation < 0) {
        return 1;
    }
    let elevation_index = Math.min(Math.floor(sun_elevation / 15), 5);
    let azimuth_index = Math.min(Math.floor(sun_azimuth / 15), 23);
    return shadowProfile[azimuth_index][elevation_index];
}

const getDiffuseShadowFactor = (shadowProfile, azimuth, verbose = false) => {

    if (shadowProfile == null) return 0

    const minAzimuth = toLimits(azimuth - 90)
    const maxAzimuth = toLimits(azimuth + 90)

    const Index1 = Math.floor(minAzimuth / 15)
    const Index2 = Math.floor(maxAzimuth / 15)

    const minIndex = Math.min(Index1, Index2)
    const maxIndex = Math.max(Index1, Index2)

    verbose && console.log("minIndex", minIndex, "maxIndex", maxIndex)

    const shadowProfileFacingAzimuth = shadowProfile.slice(minIndex, maxIndex + 1);
    verbose && console.log("shadowProfileFacingAzimuth", shadowProfileFacingAzimuth)

    const totalShadowing = shadowProfileFacingAzimuth.reduce((a, b) => a + b.reduce((c, d) => c + d, 0), 0) / (shadowProfileFacingAzimuth.length * shadowProfileFacingAzimuth[0].length)
    verbose && console.log(`Total shadowing ${azimuth}:`, totalShadowing)

    return totalShadowing
}

export const getYearlyPowerProfile = (
    orientation = defaultOrientation,
    location = defaultLocation,
    shadowProfile = createEmptyShadowProfile(),
    weatherProfile = defaultWeatherProfile,
    sparseProfile = false) => {

    verbose && console.log("[Calculator] [yieldCalculation] ShadowProfile", shadowProfile, "weatherProfile", weatherProfile)

    let daysToCalculate = sparseProfile ? [1, 91, 182, 273] : [...Array(365).keys()].map(x => x + 1)
    let powerProfile = []

    const diffuseShadowFactor = getDiffuseShadowFactor(shadowProfile, orientation.azimuth)

    if (weatherProfile == null) { weatherProfile = defaultWeatherProfile }
    weatherProfile.length != 12 && console.warn("Only monthly weatherProfile supported for now. Expected length 12, got", weatherProfile.length)

    for (let dayOfYear of daysToCalculate) {

        let monthIndex = Math.floor(dayOfYear / 30)
        monthIndex = monthIndex > 11 ? 11 : monthIndex

        const weatherProductionFactor = weatherProfile[monthIndex] / 100 // production factor is in percent

        weatherProductionFactor > 1 && console.warn("weatherProductionFactor > 100%!", weatherProductionFactor)
        weatherProductionFactor === undefined && console.warn("No weatherProductionFactor found for monthIndex", monthIndex, "dayOfYear", dayOfYear, "weatherProfile", weatherProfile)

        let date = new Date("2023-01-01T00:00:00Z");
        date.setDate(dayOfYear);
        powerProfile.push(getHourlyPowerProfile(orientation, location, date, shadowProfile, weatherProductionFactor, diffuseShadowFactor))

    }
    return powerProfile;
}

export const getHourlyPowerProfile = (orientation, location, date, shadowProfile = createEmptyShadowProfile(), weatherProductionFactor, diffuseShadowFactor = 0) => {
    // output in Wh
    let powerProfile = new Array(24).fill(0)
    for (let hour = 0; hour < 24; hour++) {
        let dateAndTime = date;
        dateAndTime.setHours(hour);
        powerProfile[hour] = getPowerForSetupAtDateAndTime(orientation, location, dateAndTime, shadowProfile, weatherProductionFactor, diffuseShadowFactor)
    }
    return powerProfile;
}

export const getPowerForSetupAtDateAndTime = (orientation = defaultOrientation, location = defaultLocation, dateAndTime, shadowProfile = createEmptyShadowProfile(), weatherProductionFactor = 1, diffuseShadowFactor = 0, verbose = false) => {
    if (dateAndTime == undefined) {
        console.error("No date given for power calculation")
        return
    }
    let sunPosition = getSunPos(dateAndTime, location)
    let solarIrradiance = calculateSolarIrradiance(orientation.azimuth, sunPosition.azimuth, orientation.tilt, sunPosition.altitude)
    let atmosphericCorrectionFactor = atmosphericCorrection(sunPosition.altitude)
    let shadowCorrectionFactor = 1 - shadowCorrection(sunPosition.altitude, sunPosition.azimuth, shadowProfile)

    let directRadiation = solarIrradiance * atmosphericCorrectionFactor

    let diffuseRadiation = calculateSolarIrradiance(0, sunPosition.azimuth, 0, sunPosition.altitude) * atmosphericCorrectionFactor * (1 + Math.cos(orientation.tilt)) / 2

    let power = directRadiation * shadowCorrectionFactor * weatherProductionFactor + diffuseRadiation * (1 - diffuseShadowFactor) * (1 - weatherProductionFactor)

    if (verbose) {
        console.log("solarIrradiance", solarIrradiance,
            "atmosphericCorrectionFactor", atmosphericCorrectionFactor,
            "shadowCorrectionFactor", shadowCorrectionFactor)
    }

    return power
}

export function calculateSolarIrradiance(module_azimuth, sun_azimuth, module_tilt, sun_elevation, verbose = false) {
    if (module_azimuth == undefined) {
        console.error("No module_azimuth given for solar irradiance calculation")
        return
    }
    if (sun_azimuth == undefined) {
        console.error("No sun_azimuth given for solar irradiance calculation")
        return
    }
    if (module_tilt == undefined) {
        console.error("No module_tilt given for solar irradiance calculation")
        return
    }
    if (sun_elevation == undefined) {
        console.error("No sun_elevation given for solar irradiance calculation")
        return
    }

    // flat module is module_tilt = 0
    if (sun_elevation < 0) {
        return 0;
    } else {
        let a = radians(module_azimuth)
        let b = radians(sun_azimuth)
        let c = radians(module_tilt)
        let d = radians(sun_elevation)

        let result = Math.cos(d) * Math.sin(c) * Math.cos(a - b) + Math.sin(d) * Math.cos(c)

        if (verbose) {
            console.log("module_azimuth", module_azimuth, "sun_azimuth", sun_azimuth, "module_tilt", module_tilt, "sun_elevation", sun_elevation)
            console.log("a", a, "b", b, "c", c, "d", d)
        }
        return result > 0 ? result : 0;
    }
}
