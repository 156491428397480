import { useState } from 'react';
import { Canvas } from '@react-three/fiber'
import { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';


export default function HudCanvas({ children, style = {} }) {
    const containerRef = useRef();

    function CameraAdjuster() {
        const { camera } = useThree();

        if (!containerRef.current) return null;

        let w = containerRef.current.clientWidth;
        let h = containerRef.current.clientHeight;
        let viewSize = h;
        let aspectRatio = w / h;

        const _viewport = {
            viewSize: viewSize,
            aspectRatio: aspectRatio,
            left: (-aspectRatio * viewSize) / 2,
            right: (aspectRatio * viewSize) / 2,
            top: viewSize / 2,
            bottom: -viewSize / 2,
            near: -100,
            far: 100
        }

        // useEffect(() => {

        camera.left = _viewport.left;
        camera.right = _viewport.right;
        camera.top = _viewport.top;
        camera.bottom = _viewport.bottom;

        camera.near = 0.1;
        camera.far = 1000;

        camera.updateProjectionMatrix();
        // }, [camera]);

        return null;
    }


    return <Canvas orthographic camera={{ position: [0, 0, 0] }} ref={containerRef} style={style}>
        <CameraAdjuster />
        {children}
    </Canvas>
}