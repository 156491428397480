import { Camera } from '@mui/icons-material';
import { useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Shape, ShapeGeometry, MeshBasicMaterial, Mesh, RingGeometry } from 'three';
import { Canvas } from '@react-three/fiber';
import { useEffect, useRef } from 'react';

import { degToRad, radToDeg, getViewDirection } from './utils';


const testRequestedSnapshots = [
    { "azimuth": 90, "altitude": 0, "roll": 0, "taken": false }
]

const calcPointingDirAndDistance = (snapshot, cameraRef) => {
    const viewDir = getViewDirection(cameraRef)

    const delta = {
        azimuth: snapshot.azimuth - viewDir.azimuth,
        altitude: snapshot.altitude - viewDir.altitude,
        roll: - viewDir.roll

    }


    const pointingDir = radToDeg(Math.atan2(delta.altitude, delta.azimuth)) - viewDir.roll
    const angleDistance = Math.sqrt(delta.azimuth ** 2 + delta.altitude ** 2)

    return { pointingDir, angleDistance };
};

export default function SeekerOverlayThree({
    color = 'red',
    size = 100,
    requestedSnapshots = testRequestedSnapshots,
    mainCameraRef = null,
}) {

    const { scene } = useThree();
    const arrowMeshes = useRef({});
    const ringMeshRef = useRef();


    useFrame(() => {
        if (mainCameraRef.current) {

            // Remove all existing arrows from the scene
            for (const key in arrowMeshes.current) {
                scene.remove(arrowMeshes.current[key]);
                arrowMeshes.current[key].geometry.dispose();
                arrowMeshes.current[key].material.dispose();
            }
            arrowMeshes.current = {};

            requestedSnapshots.forEach((snapshot, index) => {
                const { pointingDir, angleDistance } = calcPointingDirAndDistance(snapshot, mainCameraRef);

                if (!snapshot.taken) {
                    overlayArrow(pointingDir, angleDistance, index)
                }

            })
        }
    }
    )


    const overlayArrow = (pointingDirDeg = 30, angleDistance = 1, index = 0) => {
        // Step 1: Create a new Arrow geometry
        const shape = new Shape();
        const arrowScale = 30
        shape.moveTo(0, - arrowScale);
        shape.lineTo(0, arrowScale);
        shape.lineTo(arrowScale, 0);
        shape.lineTo(0, -arrowScale);
        const geometry = new ShapeGeometry(shape);

        // Step 2: Create a new Mesh
        const belowAngleFading = 10
        const material = new MeshBasicMaterial({ color: color, transparent: true, opacity: 1 - (belowAngleFading / angleDistance) });
        const arrow = new Mesh(geometry, material);

        // Step 3: Add the Mesh to the scene
        scene.add(arrow);
        arrowMeshes.current[index] = arrow;


        // Step 4: Update the position and rotation of the arrow
        const distanceToOrigin = size + 5
        const x = distanceToOrigin * Math.cos(degToRad(pointingDirDeg))
        const y = distanceToOrigin * Math.sin(degToRad(pointingDirDeg))
        arrow.position.set(x, y, -5);
        arrow.rotation.set(0, 0, degToRad(pointingDirDeg));

        return () => {
            if (arrowMeshes.current[index]) {
                scene.remove(arrowMeshes.current[index])
            }
        }
    }

    const overlayRing = () => {
        // Create a ring
        const width = 10
        const outerRadius = size;
        const innerRadius = outerRadius - width;
        const thetaSegments = 128; // Number of segments. A higher number means the ring will be more round
        const geometry = new RingGeometry(innerRadius, outerRadius, thetaSegments);



        // Step 2: Create a new Mesh
        const material = new MeshBasicMaterial({ color: color });
        const ring = new Mesh(geometry, material);

        // Step 3: Add the Mesh to the scene
        scene.add(ring);
        ringMeshRef.current = ring;

        // Step 4: Update the position and rotation of the ring
        ring.position.set(0, 0, -5);
        ring.rotation.set(0, 0, 0);

        return () => {
            if (ringMeshRef.current) {
                scene.remove(ringMeshRef.current.ring)
            }
        }
    }

    useEffect(() => {
        // overlayArrow()
        overlayRing()
    }, [])

    return null
}