import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ByBesserAiFooter({ visible, appVersion = null }) {
    const { t } = useTranslation();

    return (
        <>
            {visible && <div style={{
                position: 'absolute', bottom: '-40px', transition: ' opacity 0.2s ease-in-out', opacity: visible ? '1' : '0',
            }}>
                <a
                    href="https://www.besser.ai"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        textDecoration: 'none',
                        color: "#3182ce",
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="body2">
                        {t("Powered by")}&nbsp;
                    </Typography>
                    <img
                        src="/besser-ai_logo.png"
                        alt="besser.ai Logo"
                        width={'30em'}
                    />
                    <Typography variant="body2">
                        &nbsp; besser.ai
                    </Typography>
                </a>
            </div>}
            {appVersion && <div style={{ position: 'absolute', bottom: '-60px', transition: ' opacity 0.2s ease-in-out', opacity: visible ? '1' : '0', }}>
                    <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center', color: "grey", fontSize: 10 }}>
                        app version: {appVersion}
                    </Typography>
                </div>}
        </>
    );
}
