import { Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, Card, CardContent, CardMedia, Typography, Button, Grid } from "@mui/material";
import { yellow } from "@mui/material/colors";
import { Divider } from "@mui/material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SavingsIcon from '@mui/icons-material/Savings';

import SavingsPerYearGrid from './SavingsPerYearGrid';
import SystemPresentationSection from './SystemPresentationSection';
import IconItemBox from "./IconItemBox";
import ResultScreenGraph from "./ResultScreenGraph";

import { useTranslation } from 'react-i18next';

import React from 'react';
import Co2Icon from '@mui/icons-material/Co2';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

export default function SavingsOverviewTile({ systems }) {
    const { t } = useTranslation();

    const maxSavingsEuro = Math.max(...systems.map(system => system.savingsEuro));
    const maxSavingsCO2 = Math.max(...systems.map(system => system.savingsCO2));
    const minAmortizationTime = Math.min(...systems.map(system => system.amortizationTime));

    const theme = useTheme();

    const bestReturnRate = (100 / minAmortizationTime)

    const sectionSx = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        paddingTop: 3,
        paddingBottom: 3
    }

    // console.log(system.graphData)


    const iconSize = 50;


    return (<div data-testid="system-tile">
        {/* <Card sx={{
            // border: "none",
            // boxShadow: "none",
            // border: `1px solid ${theme.palette.background.secondary}`,
            paddingLeft: 2,
            paddingRight: 2,
            flexShrink: 0
        }}>
            <CardContent> */}

        {/* <Divider /> */}

        {/* <SavingsPerYearGrid savingsCO2={maxSavingsCO2} savingsEuro={maxSavingsEuro} amortizationTime={minAmortizationTime} returnRate={bestReturnRate} /> */}

        <Grid container spacing={4} sx={{ paddingTop: 3, paddingBottom: 3 }}>
            <IconItemBox
                icon={<Co2Icon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={maxSavingsCO2.toFixed(0)}
                unit={t("kg / a")}
                tooltip={t("co2savings tooltip")}
                heading={t("Saves")}
            />
            <IconItemBox
                icon={<SavingsIcon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={maxSavingsEuro.toFixed(0)}
                unit={t("€ / a")}
                tooltip={t("moneysavings tooltip")}
                heading={t("Saves")}
            />
            <IconItemBox
                icon={<HourglassEmptyIcon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={minAmortizationTime.toFixed(1)}
                unit={t("years")}
                tooltip={t("paysoffafter tooltip")}
                heading={t("Pays off in")}
            />
            <IconItemBox
                icon={<TrendingUpIcon sx={{ color: theme.palette.secondary.main, fontSize: iconSize }} />}
                value={bestReturnRate.toFixed(1)}
                unit="% p.a."
                tooltip={t("returns tooltip")}
                heading={t("Return on Investment")}
            />
        </Grid >

        {/* <Divider /> */}

        {/* <Grid item xs={10} sx={sectionSx}> */}

        {/* </Grid> */}

        {/* {system.graphData &&
                    <Accordion sx={{ boxShadow: 'none', border: 'none', borderTop: 'none' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{ justifyContent: 'center' }}
                        >
                            <Box flexGrow={1}>
                                <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                                    Details
                                </Typography>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>

                            <ResultScreenGraph graphData={system.graphData} />
                        </AccordionDetails>
                    </Accordion>
                } */}

        {/* </CardContent>
        </Card> */}
    </div>
    );
}
