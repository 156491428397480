import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Divider, Typography } from '@mui/material';
import SystemTile from './ResultScreenComponents/SystemTile';
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SavingsOverviewTile from './ResultScreenComponents/SavingsOverviewTile';
import { useTheme } from '@mui/material/styles';
import Graph from '../M7Calculator/Graph';
import { SimpleTable } from '../M7Calculator/ResultTable';

export default function ResultScreen(props) {
    const { bestSystem, systems, graphData, yearlyBalanceValues, location } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const [expandedSection, setExpandedSection] = React.useState(["overview", "calculations", "systems"][0]);

    const amortizationTime = bestSystem.amortizationTime;

    let resultMessage = t("Congrats")
    let resultText = t("CongratsText")
    if (amortizationTime > 7) {
        resultMessage = t("LongRun")
        resultText = t("LongRunText")
    } else if (amortizationTime > 13) {
        resultMessage = t("NotWorth")
        resultText = t("NotWorthText")
    }

    const handleClick = (e) => {
        if (expandedSection == e) {
            e = "overview"
        }
        setExpandedSection(e)
    }

    const electricityPrice = 0.33;

    return (
        <>

            <Accordion expanded={(expandedSection == "overview")} sx={{ boxShadow: 'none', border: 'none', borderTop: 'none' }} onClick={() => handleClick("overview")}>
                <AccordionSummary
                    sx={{ justifyContent: 'center', display: "flex", flexDirection: 'column' }}
                >
                    <div style={{ justifyContent: 'center', display: "flex", flexDirection: 'column' }}>
                        <Typography variant="h5" align="center" style={{ paddingTop: 5 }}>
                            {resultMessage}
                        </Typography>
                        <Typography variant="body2" align="center" style={{ paddingBottom: 20 }}>
                            {resultText}
                        </Typography>
                    </div>
                </AccordionSummary>

                <SavingsOverviewTile systems={systems} />
            </Accordion>

            <Box m={2} sx={{ width: "90%", maxWidth: 500, paddingBottom: 10 }}>
                <Accordion expanded={(expandedSection == "calculations")} sx={{ border: 1, borderColor: theme.palette.primary.main }} onClick={() => handleClick("calculations")}>
                    <AccordionSummary
                        sx={{ justifyContent: 'center', display: "flex", flexDirection: 'column' }}
                    >
                        <Typography align="center" style={{ margin: 5 }}>
                            {t("display explanation")}
                        </Typography>
                    </AccordionSummary>

                    <Typography align="center" style={{ paddingTop: 5 }}>
                        {t('optimalSolarSystem', {
                            yearlyYield: yearlyBalanceValues.yearlyYield.toFixed(0),
                            electricityPrice: electricityPrice.toFixed(2),
                            electricityWorth: (yearlyBalanceValues.yearlyYield * electricityPrice).toFixed(0)
                        })}
                    </Typography>

                    {graphData.production && <Graph data={graphData.production} options={{ scales: { x: { min: 0, max: 24 } } }} />}

                    <Typography align="center" style={{ margin: 5 }}>
                        {t('calc explanation')}
                    </Typography>
                </Accordion>
            </Box>

            <Box m={2} sx={{ width: "90%", maxWidth: 500, paddingBottom: 10 }}>


                {/* <SystemTile system={bestSystem} style='extended' /> */}

                <Accordion expanded={(expandedSection == "systems")} sx={{ border: 1, borderColor: theme.palette.primary.light }}>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{ justifyContent: 'center' }}
                        onClick={() => handleClick("systems")}
                    >
                        <Box flexGrow={1}>
                            <Typography sx={{ paddingRight: 3, paddingLeft: 3, textAlign: 'center' }}>
                                {t("Recommended systems")}
                            </Typography>
                            {/* <ExpandMoreIcon /> */}
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        {systems.map((system, i) => (
                            <React.Fragment key={i}>
                                <SystemTile system={system} style='compact' key={i} location={location} />
                                {/* <Divider /> */}
                            </React.Fragment>
                        ))}
                    </AccordionDetails>
                </Accordion>
            </Box>

        </>
    )
}
