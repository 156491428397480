import Stack from '@mui/material/Stack';
import { Slider } from '@mui/material';
import ShadowProfiler from './ShadowProfiler/ShadowProfiler'
import { Typography } from '@mui/material';
import "./InputSection.css"
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
// import setInputValues from './Calculator';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';

export default function InputSection({ inputValues, setInputValues, calculateYearlyProfile, findBestSystem, calculating }) {

    const [useManualShadowProfile, setUseManualShadowProfile] = useState(false);
    const azimuthSliderMarks = [{ value: 90, label: 'East', }, { value: 180, label: 'South', }, { value: 270, label: 'West', },];
    const tiltSliderMarks = [{ value: 90, label: '90°', }, { value: 45, label: '45°', }, { value: 0, label: '0°', },];
    const wattageSliderMarks = [{ value: 800, label: '800W', }, { value: 0, label: '0W', }, { value: 400, label: '400W', },];

    return (
        <div className="calculator-input-container" style={{ postition: "relative" }}>
            Panel Properties:
            <Stack direction="row" spacing={1} flexWrap="wrap" width={"100%"}>
                {/* <Stack direction="row" spacing={1} width={20} useFlexGap flexWrap="wrap"> */}
                {/* <div className="calculator-input">
                    <label htmlFor="azimuth">Azimuth:</label>
                    <input className='calculator-input' type="number" id="azimuth" value={inputValues.orientation.azimuth} onChange={(e) => setInputValues.setAzimuth(e.target.value)} />
                </div> */}

                <div className="calculator-input" >
                    <Typography id="azimuth-slider-label" gutterBottom>
                        Azimuth:
                    </Typography>

                    <Slider
                        aria-labelledby="azimuth-slider-label"
                        defaultValue={50}
                        value={inputValues.orientation.azimuth}
                        onChange={(e) => setInputValues.setAzimuth(e.target.value)}
                        valueLabelDisplay="auto"
                        min={0} // Set the minimum value
                        max={359} // Set the maximum value
                        step={1} // Set the step value (increments)
                        marks={azimuthSliderMarks}
                        disabled={calculating}
                    />
                </div>

                <div className="calculator-input">
                    {/* <label htmlFor="tilt">Tilt:</label>
                    <input className='calculator-input' type="number" id="tilt" value={inputValues.orientation.tilt} onChange={(e) => setInputValues.setTilt(e.target.value)} /> */}
                    <Typography id="tilt-slider-label" gutterBottom>
                        Tilt:
                    </Typography>

                    <Slider
                        width={9}
                        aria-labelledby="tilt-slider-label"
                        defaultValue={50}
                        value={inputValues.orientation.tilt}
                        onChange={(e) => setInputValues.setTilt(e.target.value)}
                        valueLabelDisplay="auto"
                        min={0} // Set the minimum value
                        max={90} // Set the maximum value
                        step={1} // Set the step value (increments)
                        marks={tiltSliderMarks}
                        disabled={calculating}
                    />
                </div>


                <div className="calculator-input">
                    {/* <label htmlFor="wattage">Panel Power [W]:</label>
                    <input className='calculator-input' type="number" id="wattage" value={inputValues.wattage} onChange={(e) => setInputValues.setWattage(e.target.value)} /> */}
                    <Typography id="wattage-slider-label" gutterBottom>
                        Panel Power [W]:
                    </Typography>

                    <Slider
                        aria-labelledby="wattage-slider-label"
                        defaultValue={50}
                        value={inputValues.wattage}
                        onChange={(e) => setInputValues.setWattage(e.target.value)}
                        valueLabelDisplay="auto"
                        min={0} // Set the minimum value
                        max={800} // Set the maximum value
                        step={20} // Set the step value (increments)
                        marks={wattageSliderMarks}
                        disabled={calculating}
                    />
                </div>


                {/* <Grid container spacing={2} padding={2}>
                    <Grid item xs={6}>
                        <TextField
                            label="Longitude"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                                inputProps: {
                                    min: -180,
                                    max: 180,
                                    step: 0.0001, // Adjust the step as needed
                                },
                            }}
                            value={inputValues.location.longitude}
                            onChange={(e) => setInputValues.setLongitude(e.target.value)}
                            disabled={calculating}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Latitude"
                            variant="outlined"
                            type="number"
                            fullWidth
                            InputProps={{
                                inputProps: {
                                    min: -90,
                                    max: 90,
                                    step: 0.0001, // Adjust the step as needed
                                },
                            }}
                            value={inputValues.location.latitude}
                            onChange={(e) => setInputValues.setLatitude(e.target.value)}
                            disabled={calculating}
                        />
                    </Grid>
                </Grid> */}

            </Stack >

            <Typography>
                <Checkbox
                    checked={inputValues.useLocalWeatherInCalculation}
                    onChange={(e) => setInputValues.setUseLocalWeatherInCalculation(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={calculating} />
                Take into account weather
            </Typography>

            <Typography>
                <Checkbox
                    checked={useManualShadowProfile}
                    onChange={(e) => setUseManualShadowProfile(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={calculating} />
                Use Manual Shadow Profile
            </Typography>

            {useManualShadowProfile &&
                <Accordion style={{ width: "100%" }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Create Manual Shadow Profile</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ShadowProfiler shadowProfile={inputValues.shadowProfile} setShadowProfile={setInputValues.setShadowProfile} />
                    </AccordionDetails>
                </Accordion>
            }

            <div style={{ padding: "20px" }}>
                <Button style={{ margin: 10 }} variant="contained"
                    onClick={calculateYearlyProfile} disabled={calculating}>
                    Calculate!
                </Button>
                <Button style={{ margin: 10 }} variant="contained"
                    onClick={findBestSystem} disabled={calculating}>
                    Find best System!
                </Button>
            </div>

            {/* 
            {true && (
                // <div style={{ position: "relative" }}>
                <img
                    src="loading.gif"
                    // src="earth.jpg"
                    alt="Loading"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 9999,
                    }}
                />
                // </div>
            )} */}

        </div >)
}