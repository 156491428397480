import { Button, Typography, TextField, Checkbox, FormControlLabel, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { green, yellow } from '@mui/material/colors';
import { useEffect } from 'react';

export default function ModulePage({ children, reference, bgcolor = null, height = "92vh" }) {

    const sx = {
        height: height,
        minHeight: "92vh",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        /* height: 100vh; */
    }

    if (bgcolor) {
        sx.bgcolor = bgcolor
    }

    useEffect(() => {
        if (reference.current) {
            reference.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

    return (
        <>
            <Box sx={sx} ref={reference}>
                {/* <Box sx={sx}> */}
                {children}
            </Box>
        </>);
}