import IconTextSection from './IconTextSection';
import { useTranslation } from 'react-i18next';



export default function ExplanatorySection({ language }) {
    const { t } = useTranslation();

    const panoramaExplanation = { image: "scan.png", text: t("panoramaExplanation") }

    const calculateExplanation = { image: "brain.png", text: t("calculateExplanation") }

    const resultsExplanation = { image: "results.png", text: t("resultsExplanation") }


    const boxSx = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 3,
        width: '100%',
        backgroundColor: 'lightgrey',
    }

    return (
        <>
            <IconTextSection content={panoramaExplanation} />
            <IconTextSection content={calculateExplanation} />
            <IconTextSection content={resultsExplanation} />
        </>
    );
}
