import "./ShadowProfiler.css"
import { useEffect, useState } from "react"

export default function ShadowBox({ i_row, i_col, drawState, shadowProfile, setShadowProfile }) {

    const mouseAction = (e) => {
        // check if mouse is down
        if (e.buttons !== 1) { return }

        if (drawState === "shadow") {
            let newShadowProfile = [...shadowProfile]
            newShadowProfile[i_col][i_row] = 1
            setShadowProfile(newShadowProfile)
        }
    }

    const handleClick = (e) => {
        // setBoxShadowed(!boxShadowed)
        let newShadowProfile = [...shadowProfile]
        newShadowProfile[i_col][i_row] = shadowProfile[i_col][i_row] ? 0 : 1
        setShadowProfile(newShadowProfile)
    }

    return (
        <span className="box" style={{ backgroundColor: shadowProfile[i_col][i_row] ? "black" : "white" }} key={`${i_col}x${i_row}`} onMouseOver={mouseAction} onClick={handleClick} > </span >
    )
}