// import { getAverageSunshineHours } from './brightSkyApiAccess';
import { getAverageSunshineHours } from './meteostatApiAccess';
import { getAverageSunAboveHorizonHoursForMonth } from './suncalcUtils';


export default async function calcSunshinePercentagePerMonth(lat, lon, useTestData = false) {

    // console.log("inseide calcSunshinePercentagePerMonth", lat, lon)
    const avgSunHrsPerMonth = await getAverageSunshineHours({ latitude: lat, longitude: lon, useTestData: useTestData })

    let potDailySunHrsPerMonth = Array.from({ length: 12 }, (_, i) => i + 1)
    potDailySunHrsPerMonth = potDailySunHrsPerMonth.map((month) => getAverageSunAboveHorizonHoursForMonth(month, lat, lon))

    // console.log("potSunHrsPerMonth", potDailySunHrsPerMonth)
    // console.log("avgSunHrsPerMonth", avgSunHrsPerMonth)

    const sunshinePercentagePerMonth = avgSunHrsPerMonth.map((avgSunHrs, i) => avgSunHrs / potDailySunHrsPerMonth[i] * 100)

    // console.log("sunshinePercentagePerMonth", sunshinePercentagePerMonth)

    return {
        sunPerc:
            sunshinePercentagePerMonth,
        avgSunHrs:
            avgSunHrsPerMonth,
        potSunHrs:
            potDailySunHrsPerMonth
    }

} 